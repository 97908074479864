import axios from 'axios' 
import { getConnectedWallet } from '../WalletSession'



const backendURI = process.env.REACT_APP_BACKEND_URI
const getLocalAccessToken = () => {
    const accessToken = localStorage.getItem('access')
    return accessToken 
}

const getLocalRefreshToken = () => {
    const refreshToken = localStorage.getItem('refresh') 
    return refreshToken 
}



const auth_instance = axios.create({
    baseURL: backendURI,
    headers: {
        'Content-Type': 'application/json',
    }, 
})






auth_instance.interceptors.request.use( (config) => {
    const token = getLocalAccessToken() 
    if(token){
        config.headers['Authorization'] = "Bearer " + token 
    }
    return config 
}, (error) => {
    return Promise.reject(error)
})


let refreshing_token = null 
auth_instance.interceptors.response.use( (response) => {
    return response 
}, async (error) => {
    const originalConfig = error.config 
    if(error.response){
        if(error.response.status == 401 && !originalConfig._retry){
            originalConfig._retry = true 
            try{
                refreshing_token = refreshing_token ? refreshing_token : refreshToken() 
                const rs = await refreshing_token
                if(rs.data.access){
                    const { access } = rs.data 
                    localStorage.setItem('access', access)
                    auth_instance.defaults.headers.common['Authorization'] = "Bearer " + access 
                }
               
                return auth_instance(originalConfig) 
            }catch(_error){
                if(_error.response && _error.response.data){
                    return Promise.reject(_error.response.data)
                }
                return Promise.reject(_error)
            }
        }

        if(error.response.status == 403 && error.response.data){
            return Promise.reject(error.response.data) 
        }
    }

    return Promise.reject(error)


})

const refreshToken = async () => {
    return await auth_instance.post('user_api/auth_refresh/', {
        refresh: getLocalRefreshToken(), 
    })
}


const getAllUsers = async () => {
    return await auth_instance.get('user_api/allusers/')
}


const signin = async (credentials) => {
    return await auth_instance.post('user_api/auth_token/', credentials)
}

const createUser = async (credentials) => {
    return await auth_instance.post('user_api/brfusers/', credentials)
}

/* Grab Authenticated User Cards */

const getAuthenticatedUserCards = async () => {
    return await auth_instance.get('brfcard_api/usercards/')
}


const getActiveCards = async () => {
    return await auth_instance.get('brfcard_api/active_cards/')
}

const getActiveSquadCount = async () => {
    return await auth_instance.get('brfcard_api/active_squad_count/')
}

const getReservedCards = async () => {
    return await auth_instance.get('brfcard_api/reserved_cards/')
}

const getTermsAccepted = async () => {
    return await auth_instance.get('user_api/terms_agreed/')
}

const acceptTermsofAgreement = async () => { 
    return await auth_instance.post('user_api/agree_to_terms/')
}


const getCardByID = async (tokenId) => {
    return await auth_instance.get(`brfcard_api/brfcards/${tokenId}/`)
}


/* Search for BullCard in Inventory */

const searchPlayers = async (value) => {
    return await auth_instance.get(`brfcard_api/bullsearch/?search=${value}`)
}


/* Actions Method  For BullCards */

//pull recently minted card

const getRecentlyMinted = async () => {
    return await auth_instance.get('brfcard_api/recent_minted_nfts/')
}

// push card to reserved
const pushToReserves = async (tokenId) => {
    let data = { squad_status : false}
    return await auth_instance.patch(`brfcard_api/brfcards/${tokenId}/`, data)
}

// push card to active squad
const pushToActive = async (tokenId) => {
    let data = { squad_status: true}
    return await auth_instance.patch(`brfcard_api/brfcards/${tokenId}/`, data)
}


/* Trait Lookup */

const searchTraits = async (trait_type, trait_value) => {
    return await auth_instance.get(`brfcard_api/trait_lookup/?trait_type=${trait_type}&value=${trait_value}`)
}

const searchMarketTraits = async (trait_type, trait_value) => { 
    return await auth_instance.get(`marketplace/market_trait_lookup/?trait_type=${trait_type}&value=${trait_value}`)
}

/* Submit Leasing */

const requestLeasing = async (data) => {
    return await auth_instance.post(`leasing/create_offer/`, data)
}


/* Get Authenticated User Leasing Offers */

const getOffers = async () => {
    return await auth_instance.get('leasing/myoffers/')
}

const getRecentOffers = async () => {
    return await auth_instance.get('leasing/recent_offers/')
}

const getSentOffers = async () => {
    return await auth_instance.get('leasing/sentoffers/')
}

const acceptOffer = async (offer_id, card_id) => {

    let data = { 
        offer_id: offer_id,
        card_id: card_id, 
    }
    return await auth_instance.post(`leasing/accept_offer/`, data)
}

const rejectOffer = async (tokenId) => {
    let data = {
        
        is_leased: false,
        accepted: false, 
        leaser: getConnectedWallet(),
        lease_requested: false,
        lease_starts: null,
        lease_ends: null,

    }

    return await auth_instance.patch(`leasing/lease_offers/${tokenId}/`, data)
}

const getAllMarketedCards = async (pageNumber) => { 
    return await auth_instance.get(`marketplace/market_cards/?page=${pageNumber}`)
}

const pushToMarketByID = async ( market_id, market_price ) => {

    let data = { 

        market_price: market_price, 
        market_id: market_id,
    }

    return await auth_instance.post('marketplace/push_to_market/', data)

} 

const removeFromMarketByID = async ( market_id ) => { 
    let data = { 
        market_id: market_id 
    }
    return await auth_instance.post(`marketplace/remove_from_market/`, data)
}

const sortMarketData = async ( sorted_value, pageNumber ) => { 
    return await auth_instance.get(`marketplace/sort_market_cards/?page=${pageNumber}&sorted_value=${sorted_value}`)
}

const sortContractData = async ( contract_value, pageNumber) => { 
    return await auth_instance.get(`marketplace/sort_smart_contract/?page=${pageNumber}&smart_contract=${contract_value}`)
}


const getListedMarketCards = async () => { 
    return await auth_instance.get('marketplace/listed_market_cards/')
}


const updateMarketPrice = async ( market_id, market_price, tokenId ) => { 
    
    let data = { 
        market_price: market_price,
        market_id: market_id, 
        tokenId: tokenId 
    }

    return await auth_instance.post(`marketplace/adjust_market_price/`, data)
}


const getUserTransactions = async () => { 
    return await auth_instance.get('transactions/my_transactions/')
}

const getRecentTransactions = async () => { 
    return await auth_instance.get('transactions/recent_transactions/')
}

const signLease = async ( data ) => { 
    
    return await auth_instance.post('leasing/sign_lease/', data)
    //call this when smart contract is successful
}

const getAllCards = async () => { 
    return await auth_instance.get('brfcard_api/brfcards/')
}

const testPostCard = async (data) => {

    return await auth_instance.post('brfcard_api/brfcards/', data) 
}

const testWebSocket = async () => { 
    return await auth_instance.post('notify/test_websocket/')
}




export {

    getLocalAccessToken, 
    getAllUsers,
    signin, 
    createUser,
    getAuthenticatedUserCards,
    getCardByID,
    searchPlayers,
    testPostCard, 
    getActiveCards,
    getActiveSquadCount,
    getReservedCards,
    pushToActive,
    pushToReserves,
    searchTraits,
    requestLeasing,
    getRecentlyMinted,
    getOffers,
    getRecentOffers,
    getSentOffers,
    acceptOffer,
    rejectOffer,
    getAllMarketedCards,
    pushToMarketByID,
    removeFromMarketByID,
    searchMarketTraits,
    sortMarketData,
    sortContractData,
    getListedMarketCards,
    updateMarketPrice, 
    getAllCards,
    signLease,
    getUserTransactions,
    getRecentTransactions,
    testWebSocket,
    getTermsAccepted,
    acceptTermsofAgreement,

}
