import React, { useEffect, useState } from 'react'
import { Chip, Divider, Drawer, Stack, Typography } from '@mui/material'

import { getConnectedChainID, getConnectedWallet, getConnectedProvider, getWeb3Provider} from '../session/WalletSession'

import LogoLoader from './LogoLoader'

import { detectChainID } from '../utils/BRFSessionUtils'
import { MintButton } from './BRFButtons'


import matic_icon from '../logos/matic_logo.png'
import MintDialog from './mintComponents/MintDialog'





const drawerWidth = 340

const BRFWalletInfo = ({status, handler}) => {

    const [provider, setProvider] = useState(getWeb3Provider())
    const [walletBalance, updateWalletBalance] = useState("")
    
    const [mintDialogStatus, setMintDialogStatus] = useState(false)


    const handleMintDialog = () => { 
        setMintDialogStatus(!mintDialogStatus)
    }

    const getWalletBalance = async () => {

        try{
            const data = await provider.eth.getBalance(getConnectedWallet())
            const eth_balance = await provider.utils.fromWei(data, 'ether' )
            //console.log(data)
            updateWalletBalance(eth_balance)
        }catch( error ){
            console.error(error)
        }
        
    } 

    useEffect(() => {     

        const fetchBalanceInterval = setInterval(() => {        

            getWalletBalance()

        }, 50000)
        return () => clearInterval(fetchBalanceInterval)


    }, [])

    //console.log('Wallet Balance: ', walletBalance)
    
    return (
   
          <Drawer
            sx={{
                width: drawerWidth,
                backgroundColor: '#111322',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: '#111322',

                },
            }}            
            anchor="right"
            open={status}
            onClose={handler}>

                <Stack
                    mt={5}
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    >

                    <Typography 
                        sx={{ color: '#66fcf1'}}
                        variant="h5"
                        fontFamily="AZN"
                        component="div">
                            Wallet Info 
                    </Typography>


                    <Divider sx={{ backgroundColor: "#66fcf1", width: "100%" }}/>


                    <Stack 

                        direction="column"
                        spacing={5}
                        justifyContent="center"
                        alignItems="center"
                        width="30vh">

                            <Stack 
                                direction="row"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center">


                                <Chip
                                    sx={{
                                            color : '#66fcf1', 
                                            fontSize: '1rem',
                                        }}
                                    variant="outlined"
                                    fontFamily="AZN"
                                    icon={<LogoLoader icon={matic_icon} width={"30px"} height={"25px"}/>                                      }
                                    label="Matic Balance"/>

                            </Stack>

                       

                        <div style={{ 
                            overflow: "hidden", textOverflow: "ellipsis",
                            width: '15rem', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>  
                                

                            <Chip
                                sx={{
                                        color : '#66fcf1', 
                                        fontSize: '3rem',
                                    }}
                                variant="text"
                                fontFamily="AZN"
                                label={walletBalance}/>

                                
                        </div>

                        <Divider sx={{ backgroundColor: "#66fcf1", width: "100%" }}/>


                        <Stack 

                            mt={10}
                            direction="column"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center">

                                    <MintButton 
                                        text="Mint"
                                        onClick={handleMintDialog}/>
                        </Stack>




                    </Stack>


                   

                    <Stack 
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        bottom="10px"
                        width="30vh">

                            <Divider sx={{ backgroundColor: "#66fcf1", width: "100%" }}/>


                             <Chip
                                sx={{
                                        color : '#66fcf1'
                                    }}
                                variant="outlined"
                                fontFamily="AZN"
                                label={getConnectedProvider()}/>

                            <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}>  
                                <Chip
                                    sx={{
                                         color : '#66fcf1'
                                        }}
                                    variant="outlined"
                                    fontFamily="AZN"
                                    label={getConnectedWallet()}/>
                            </div>

                            <Chip
                                sx={{
                                        color : '#66fcf1'
                                    }}
                                variant="outlined"
                                fontFamily="AZN"
                                label={detectChainID(getConnectedChainID())}/>


                    </Stack>

                </Stack>


                <MintDialog
                    status={mintDialogStatus}
                    handler={handleMintDialog}/>


          </Drawer>
    )
}

export default BRFWalletInfo
