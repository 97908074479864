
import React, { useState } from 'react'

import { Dialog, DialogContent, AppBar , IconButton, Toolbar, Stack, Typography, Button, useMediaQuery } from '@mui/material'

import { BRFTransition } from '../../utils/BRFTransition'
import BRFTypography from '../BRFTypography'
import CloseIcon from '@mui/icons-material/Close'




const MintInfo = ({ status, handler, message, fullMessage }) => { 

    const mobileSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [expanded, setExpanded] = useState(false)

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }
    return ( 
        
        <Dialog 
            fullWidth
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

            <AppBar sx={{ position: 'relative'}}>
                <Toolbar>

                    <IconButton 
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">
                            <CloseIcon/>
                    </IconButton>

                    <BRFTypography text="Info"/>

                </Toolbar>
            </AppBar>


            <DialogContent
                sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                
                <Stack
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <Typography
                        sx={{ color: '#ffffff'}}
                        variant="h4"
                        component="div">
                        {message}
                    </Typography>
                    <Typography
    sx={{
        color: '#FF0000', // Change to your desired error text color
        fontFamily: 'Roboto Slab',
        flex: true,
        variant: 'p',
        lineHeight: { xs: '1rem', md: '2rem' },
        component: 'div',
        textAlign: 'justify',
        flexWrap: 'wrap',
        fontSize: { xs: '.5rem', md: '1rem' },
        style: {
            transition: 'max-height 0.5s ease',
        },
    }}
>
    {fullMessage}
</Typography>


                    {!expanded && mobileSize && (
                        <Button
                        color="primary"
                        onClick={toggleExpanded}
                        style={{ cursor: 'pointer', textDecoration: 'underline'}}
                        >
                            <Typography sx={{ color: '#FFFFFF'}}
                                fontFamily='Roboto Slab'
                                flex="true"
                                variant="p">
                                Continue Reading
                            </Typography>
                        </Button>
                    )}
                </Stack>

            </DialogContent>
        </Dialog>
    )
}

export default MintInfo