

import React, { useState, useEffect } from 'react'

import { Badge, IconButton } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotifySocket from '../../session/NotifySocket'
import { getLocalAccessToken } from '../../session/backend_session/Backend'


let viewed_count = 0
let unviewed_count = 0 
let received_count = 0 
let total_count = 0 




const Notify = ({ onClick }) => { 



    const [ socket, setSocket ] = useState()

    const [ notifyContainer, updateNotifyContainer ] = useState([])
    const [ notifyCount , updateNotifyCount ] = useState(0)
    



    const connectNotify = () => { 


        if(!socket){
            console.log('Error with socket')
            return
        }

        socket.onopen = (e) => { 

        }


        socket.onclose = (e) => { 

        }

        socket.onmessage = (e) => { 

            const received_data = JSON.parse(e.data)
            
            switch(received_data.type){

                case "notification.message":
                    updateNotifyContainer( (prevData) => [...prevData, received_data.data])
                    received_count++
                    break 
                case "viewed.notification": 
                    received_data.data.map((data) => { 
                        updateNotifyContainer( (prevData) => [...prevData, data])
                        viewed_count++
                    })
                    break 

                case "unviewed.notification": 
                    received_data.data.map((data) => { 
                        updateNotifyContainer( (prevData) => [...prevData, data])
                        unviewed_count++
                    })
                    break 
                default: 
                    break 
                    
            }
            // get total notification count from socket data 
            total_count = viewed_count + unviewed_count + received_count
            console.log('Data Received', total_count) 
            updateNotifyCount(total_count)

        }


        socket.onerror = (e) => { 
            console.error(e) 
            socket.close() 
        }
    

    }



    useEffect(() => { 
        
        const _wsSocket = new NotifySocket(getLocalAccessToken()) 
        setSocket(_wsSocket)
        connectNotify()
        
    }, [])

    //console.log('Amount of Data: ', notifyCount)

    return ( 
        <IconButton 
            sx={{ 
                backgroundColor: '#37363b',
                ":hover": {
                    backgroundColor: '#9D9CA1'
                }
            }} 
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
            onClick={onClick}>
            <Badge badgeContent={notifyCount} color="error">
                <NotificationsIcon />
            </Badge>
        </IconButton> 
    )

}


export  { 
    Notify, 
}