import React, { useState } from 'react' 

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Tooltip } from '@mui/material'
import { Grid, Box, Stack } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { BRFTransition } from '../../utils/BRFTransition'
import BullCard from '../BRFCard/BullCard'
import AdjustPrice from './AdjustPrice'
import VerifyRemove from './VerifiyRemove'
import { MarketPriceDisplay } from '../../pages/marketplace/Marketplace'

const ListedCards = ({ status, handler, dataContainer, refreshContainer}) => { 


    const [tokenID, setTokenID] = useState(null)
    const [marketID, setMarketID] = useState(null)

    const [ pricePanelStatus, setPricePanelStatus ] = useState(false) 
    const [ verifyPanelStatus, setVerifyPanelStatus ] = useState(false) 


    const handlePricePanel = () => { 
        setPricePanelStatus(!pricePanelStatus)
    }

    const handleVerifyPanel = async () => { 
        await refreshContainer() 
        setVerifyPanelStatus(!verifyPanelStatus) 
    }

    console.log(`Token ID ${tokenID} && Market ID ${marketID}`)

    return ( 
        <Dialog 
            open={status}
            TransitionComponent={BRFTransition}
            keepMounted
            onClose={handler}
            fullScreen>

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close"
                    
                    >
                    <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                        Current Cards on Market 
                    </Typography>
                </Toolbar>
            </AppBar>



            <DialogContent

                display="flex"
                sx={{ backgroundColor: '#111321', maxWidth: '100%'}}> 

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">


                <Grid 
                    sx={{ mb: 10, maxWidth: "100%" }}
                    container 
                    spacing={{ xs: 2, md: 1, lg: 2}}
                    columns={{ xs: 1, sm: 0, md: 12 }}
                    display="flex"
                    justifyContent={{xs: 'center', md: 'space-evenly'}}
                    alignItems="center">
                        
                        
                        
                        
                    {dataContainer.map(( card, key ) => ( 

                        <Grid 
                            item 
                            xs={2}
                            sm={4}
                            md={3} 
                            key={key}>

                                <Stack 
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex"
                                    spacing={0}>

                                <BullCard 
                                    data_handler={card}
                                    from={"team"}/>

                                

                                    
                                    <MarketPriceDisplay price={card.market_details.market_price}/>

                                    <Stack 
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center">

                                        <Tooltip 
                                            placement="top-start"
                                            title={`Adjust ${card.name} Price`}>
                                            <IconButton 
                                                sx={{ 
                                                    backgroundColor: '#37363b',
                                                        ":hover": {
                                                        backgroundColor: '#9D9CA1'
                                                    }
                                                    }} 
                                                size="large"
                                                aria-label="edit listed card"
                                                color="inherit"
                                                onClick={() =>{ 
                                                    setTokenID(card.tokenId)
                                                    setMarketID(card.market_details.market_id) 
                                                    handlePricePanel() 
                                                }}
                                                >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>


                                        <Tooltip 
                                            placement="top-start"
                                            title="Remove From Market">
                                            <IconButton 
                                                sx={{ 
                                                    backgroundColor: '#DE3163',
                                                        ":hover": {
                                                        backgroundColor: '#DE3163'
                                                    }
                                                    }} 
                                                size="large"
                                                aria-label="edit listed card"
                                                color="inherit"
                                                onClick={() => { 

                                                    setTokenID(card.tokenId)
                                                    setMarketID(card.market_details.market_id)
                                                    handleVerifyPanel()
                                                }}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>

                                </Stack>
                            </Stack>
                        </Grid>

                    ))}

                </Grid>
                </Box>


                <AdjustPrice
                    status={pricePanelStatus}
                    handler={handlePricePanel}
                    market_id={marketID}
                    tokenId={tokenID}
                    refresh={refreshContainer}/>


                <VerifyRemove
                    status={verifyPanelStatus}
                    handler={handleVerifyPanel} 
                    tokenId={marketID}
                    refresh={refreshContainer}/>

            
            
            </DialogContent>



        </Dialog> 

    )
}

export default ListedCards 