


class NotifySocket { 

    constructor(_authToken){ 
        this.authToken = _authToken
        this.socketDomain = 'metaversusportaldev.com'
        this.connectURI = `wss://${this.socketDomain}/ws/notification/?token=${this.authToken}`
        this.socket = null 

        this.connectSocket()
    }

    connectSocket() { 
        if(!this.authToken){ 
            console.log('User has no access')
            return
        }

        this.socket = new WebSocket(this.connectURI)
    
    }

    getSocket() { 
        return this.socket 
    }
}



export default NotifySocket