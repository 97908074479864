


import CONTRACT_FILE from '../../utils/DynamicNfts.json'
import WHITELIST_FILE from './whiteList.json'

import { disconnectWallet, estimateGas, getConnectedProvider, getConnectedWallet } from '../WalletSession'

import { ethers } from 'ethers';

import { activateSession, handleExistingOrNewUser } from '../WalletSession';
import { getAllowListProof, getFreeMintBundleProof, getFreeMintProof } from './WhiteList';
import { keccak256 } from 'ethers/lib/utils';
import { MerkleTree } from 'merkletreejs'

const { ethereum } = window 

class MetaMaskController{ 


    constructor() { 
        this.metamaskProvider = null 
        this.web3Provider = null 
        this.contract = null 
        this.buildEnv = process.env.REACT_APP_ENV
        this.networkName = process.env.REACT_APP_NETWORK_NAME
        this.host = process.env.REACT_APP_TORUS_HOST
        this.chainId = process.env.REACT_APP_NETWORK_CHAIN 
        this.contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
        this.contractABI = CONTRACT_FILE.abi 
        this.providerID = "MetaMask"
    }

    extractAccounts = async () => { 
        if(ethereum.providers){
            this.metamaskProvider = ethereum.providers.find((provider) => provider.isMetaMask)
            const accounts = await this.metamaskProvider.request({ method: 'eth_requestAccounts' })
            return accounts 
        }
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        return accounts 
    }



    isInstalled = () => { 
        if(!ethereum.isMetaMask){
            return false
        }
        return true 
    }

    getNetworkVersion = () => { 
        return ethereum.netWorkVersion 
    }

    handleConnect = async () => { 
        let accounts = await this.extractAccounts()
        console.log('METAMASK ACCOUNTS, ', accounts)
        console.log("METAMASK CHAIN" ,this.chainId)

        const chainId = ethereum.chainId;
        this.chainId = chainId
        if (this.chainId != process.env.REACT_APP_NETWORK_CHAIN) {
            try {
                await ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x89' }],
                });
              } catch (switchError) {
                if (switchError.code === 4902) {
                  try {
                    await ethereum.request({
                      method: 'wallet_addEthereumChain',
                      params: [
                        {
                          chainId: '0x89',
                          chainName: 'Polygon Mainnet',
                          nativeCurrency: {
                            name: 'MATIC',
                            symbol: 'MATIC',
                            decimals: 18
                          },
                          rpcUrls: ['https://polygon-rpc.com/']
                        }
                      ]
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
              }
            }
        activateSession(accounts[0], this.chainId, this.providerID)
        await handleExistingOrNewUser()
    }

    updateMintStartDate = async (uIntDate) => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        await this.contract.updateMintStartDate(uIntDate)
    }

    updatePublicMintStartDate = async (uIntDate) => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        await this.contract.updatePublicMintStartDate(uIntDate)
    }

    updateAdminWallet = async (newWalletAddress) => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        await this.contract.updateAdminWallet(newWalletAddress)
    }




    isFreeBundleMintClaimed = async () => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isFreeBundleMintClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    isAllowListClaimed = async () => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isAllowListClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    isFreeMintClaimed = async () => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isFreeMintClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    bundleSaleStatus = async () => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleMintStatus = await this.contract.bundleSaleStatus()
        return bundleMintStatus

    }

    leaseCard = async (ownerAddress, leaserAddress, expires, tokenId, amount) => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }

        let leaseStatus = { 
            fullMessage: '',
        }

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        await this.contract.leaseAgreement(ownerAddress, leaserAddress, expires, tokenId, {
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: amount 
        })
        .then((tx) => { 
            this.web3Provider.waitForTransaction(tx.hash)
            leaseStatus['leaser'] = tx
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            if(error.reason){
                leaseStatus['fullMessage'] = error.reason        
            }else{
                leaseStatus['fullMessage'] = error.code 
            }
        })
        
        return leaseStatus 
    }

    withDraw = async () => { 
        if(!this.contract){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }

        let leaseStatus = { 
            fullMessage: '',
        }

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        await this.contract.withdraw({
            maxFeePerGas, 
            maxPriorityFeePerGas,
        })
        .then((tx) => { 
            this.web3Provider.waitForTransaction(tx.hash)
            leaseStatus['leaser'] = tx
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            if(error.reason){
                leaseStatus['fullMessage'] = error.reason        
            }else{
                leaseStatus['fullMessage'] = error.code 
            }
        })
        
        return leaseStatus 
    }
    
    
    handleMintBundle = async (price) => { 

        if(!this.metamaskProvider){
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        if(getConnectedProvider() == this.providerID){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

            let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

            await this.contract.mintBundle(getConnectedWallet(),{
                maxFeePerGas, 
                maxPriorityFeePerGas,
                value: price
            })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting "
                if(error.reason){
                    mintStatus['fullMessage'] = error.reason        
                }else{
                    mintStatus['fullMessage'] = error.code 
                }
            })
            return mintStatus
        }
    }

    handleMint = async (matic, quantity) => { 

        if(!this.metamaskProvider){
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        if(getConnectedProvider() == this.providerID){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

            let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

            await this.contract.mint(getConnectedWallet(), quantity, {
                maxFeePerGas, 
                maxPriorityFeePerGas,
                value: matic 
            })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error.reason)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting "
                if(error.reason){
                    mintStatus['fullMessage'] = error.reason        
                }else{
                    mintStatus['fullMessage'] = error.code 
                }
            })
            return mintStatus
        }
    }



    handleFreeMint = async (price) => { 
        if(!this.metamaskProvider){
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        if(getConnectedProvider() == this.providerID){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)


            const proof = getFreeMintProof()

            let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

            await this.contract.freeMint(getConnectedWallet(), proof, {
                maxFeePerGas, 
                maxPriorityFeePerGas,
                value: price,
            })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error.reason)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting "
                if(error.reason){
                    mintStatus['fullMessage'] = error.reason        
                }else{
                    mintStatus['fullMessage'] = error.code 
                }
            })
            return mintStatus
        }
    }


    handleFreeMintBundle = async (price) => { 
        if(!this.metamaskProvider){
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        if(getConnectedProvider() == this.providerID){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)


            const proof = getFreeMintBundleProof()

            let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

            await this.contract.freeMintBundle(getConnectedWallet(), proof, {
                maxFeePerGas, 
                maxPriorityFeePerGas,
                value: price,
            })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error.reason)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting "
                if(error.reason){
                    mintStatus['fullMessage'] = error.reason        
                }else{
                    mintStatus['fullMessage'] = error.code 
                }
            })
            return mintStatus
        }
    }

    handleAllowListMintBundle = async (price) => { 
        if(!this.metamaskProvider){
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        if(getConnectedProvider() == this.providerID){
            this.web3Provider = new ethers.providers.Web3Provider(ethereum)
            const signer = this.web3Provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)


            const proof = getAllowListProof()

            let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

            await this.contract.allowListMint(getConnectedWallet(), proof, {
                maxFeePerGas, 
                maxPriorityFeePerGas,
                value: price,
            })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error.reason)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting "
                if(error.reason){
                    mintStatus['fullMessage'] = error.reason        
                }else{
                    mintStatus['fullMessage'] = error.code 
                }
            })
            return mintStatus
        }
    }
}

export default MetaMaskController