import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BRFAlert = ({status, handler, indicator, message}) => {


    const action = (
        <React.Fragment>
        
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handler}>

                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return (
        <Snackbar
            open={status}
            autoHideDuration={6000}
            onClose={handler}
            action={action}
            >

<               div style={{overflow: "hidden", textOverflow: "ellipsis", width: '100%'}}> 

                    <Alert
                        variant="filled"
                        severity={indicator}>
                        {message} 
                    </Alert>
                </div>

        </Snackbar>
    )
}

export default BRFAlert
