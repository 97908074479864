
import React from 'react'

import {Paper, Grow, Box, CardMedia} from '@mui/material'


import sample_football_field from '../static/samples/stadium_background.png'


const TeamPanel = ({children}) => {


    return (
        <Box 
            justifyContent="center" 
            alignItems="center"
            display="flex">
        <Grow 
            in={true}
            style={{ transformOrigin: '0 0 0'}}
            {...(true ? { timeout: 1000} : {} )}>

            
            <Paper 
                elevation={3} 
                sx={{ 
                       
                        display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', 
                        backgroundImage: `url(${sample_football_field})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center',
                        width: { xs: '350px', sm: '100%', md: '100vh', lg: '150vh'}, maxWidth: "180vh",
                        height:  {xs: '80vh', sm: '80vh', md: ' 75vh'}, maxHeight: {xs: '80vh', md: '75vh'}, borderRadius: '10px', overflow: 'auto'
                    
                    }}>

                        {children} 

            </Paper>


        </Grow> 
        </Box>
    )
}

export default TeamPanel 