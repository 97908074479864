import { AppBar, Button, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/system';
import TextField from '@mui/material/TextField'; 
import { useState } from 'react';
import { metamaskController } from '../BullApp';



export default function AdminTime({ open, onClose }) {
    
    const [code, setCode] = useState('');

    const handleSubmit = async () => {
      await metamaskController.updatePublicMintStartDate()
    };
    return (

    <Dialog open={open} onClose={onClose} fullScreen >
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
          Admin
          </Typography>
          
        </Toolbar>
      </AppBar>

      <DialogContent
            display="flex" 
            
            sx={{maxWidth: '100%', backgroundSize: 'cover',
            backgroundColor: '#0f111f', height: "100%"
            }}
            >

        
<Stack alignItems="center" spacing={2}>
<Box 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
  >

    <Box 
      sx={{
        p: 2, 
        borderRadius: 1,
        backgroundColor: 'gray',
        display: 'flex',
        alignItems: 'center',
        width: 300,
      }}
    >
      <TextField 
        label="Enter Code"
        variant="outlined"
        color="primary"
        fullWidth
        value={code}
        onChange={(e) => setCode(e.target.value)}
        InputProps={{
          sx: {
            color: 'white',
            textAlign: 'center'
          }
        }}
      />
    </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>


        <Button 
            variant="contained" 
            color="error" 
            sx={{ ml: 2 }}
            onClick={() => handleSubmit()}
        >
            Decline
        </Button>
        </Box>
        </Box>
      </Stack>
      </DialogContent>
    </Dialog>
  );


}