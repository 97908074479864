import React, { useState } from 'react';
import { AppBar, Button, DialogContent, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/system';

export default function AdminSubmit({ open, onClose }) {
  const [code, setCode] = useState('');

  const handleSubmit = () => {
    console.log(handleSubmit);
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Admin
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Center the dialog content */}
      <DialogContent 
  sx={{
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%' 
  }}
>

  <Box 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
  >

    <Box 
      sx={{
        p: 2, 
        borderRadius: 1,
        backgroundColor: 'gray',
        display: 'flex',
        alignItems: 'center',
        width: 300,
      }}
    >
      <TextField 
        label="Enter Code"
        variant="outlined"
        color="primary"
        fullWidth
        value={code}
        onChange={(e) => setCode(e.target.value)}
        InputProps={{
          sx: {
            color: 'white',
            textAlign: 'center'
          }
        }}
      />
    </Box>

    <Button 
      variant="contained" 
      color="success"
      sx={{ mt: 2 }}
      onClick={handleSubmit}
    >
      Submit
    </Button>

  </Box>

</DialogContent>
    </Dialog>
  );
}
