import React, { useState }from 'react'
import { Dialog, DialogContent, FormControl, InputLabel }from '@mui/material'


import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'


import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import { BRFTransition } from '../../utils/BRFTransition'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { RegularButton } from '../BRFButtons'



const theme = createTheme({

  palette: {
    primary: {
      main: '#66fcf1', 
      dark: '#66fcf1', 
      contrastText: '#fff',
      background: '#fff'
    },

  }, 
 
})

const TraitsDataType = [
    'Background',
    'Arms',
    'Chest',
    'Face', 
    'Legs',
    'Horns', 
    'Shoes',
    'Skin',
    'Mouth',
    'Nose',
    'Cleats'
]

const TraitsDataValue = [ 
    'Common', 
    'UnCommon', 
    'Rare',
    'Epic', 
    'Legendary',

]


const TraitsPanel = ({status, handler, filter}) => { 



    const [ traitType, setTraitType] = useState("") 
    const [ traitValue, setTraitValue] = useState("") 


    const handleTraitType = (event) => { 
        setTraitType(event.target.value) 
    }

    const handleTraitValue = (event) => { 
        setTraitValue(event.target.value) 
    }

    return ( 
        <Dialog 
            fullWidth
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>
                
                <AppBar sx={{ position: 'relative'}}>
                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={handler}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>

                    </Toolbar>
                </AppBar>

                <DialogContent
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                    
                    <Stack
                        direction="column"
                        spacing={2} 
                        justifyContent="center"
                        alignItems="center" 
                        display="flex">

                        <ThemeProvider theme={theme}>

                            <FormControl fullWidth>

                                <InputLabel id="trait_type_label" sx={{ color: '#66fcf1'}}>Trait Type</InputLabel>

                                <Select 
                                    sx={{ 
                                        borderColor: '#66fcf1', backgroundColor:'#2b2d55', color: '#66fcf1'
                                    }}
                                    labelId="trait_type_label"
                                    id="trait_type_select"
                                    value={traitType}
                                    label="Trait Type"
                                    onChange={handleTraitType}>

                                        {TraitsDataType.map( (type_data, key) => (
                                            <MenuItem 
                                                sx={{ backgroundColor: '#0f111f', color: '#66fcf1', ":hover": { backgroundColor: '#2b2d55'}}}
                                                key={key} 
                                                value={type_data}>
                                                    {type_data}
                                            </MenuItem>
                                        ))}

                                </Select>

                            </FormControl>
                        </ThemeProvider>

                        <FormControl fullWidth>

                            <InputLabel id="trait_value_label" sx={{ color: '#66fcf1'}}>Trait Value</InputLabel>
                            <Select 
                                 sx={{ 
                                    borderColor: '#66fcf1', backgroundColor:'#2b2d55', color: '#66fcf1'
                                }}
                                labelId="trait_value_label"
                                id="trait_value_select"
                                value={traitValue}
                                label="Trait Value"
                                onChange={handleTraitValue}
                               >

                                    {TraitsDataValue.map((value, key) => ( 
                                        <MenuItem 
                                            sx={{ backgroundColor: '#0f111f', color: '#66fcf1', ":hover": { backgroundColor: '#2b2d55'}}}
                                            key={key} 
                                            value={value}>
                                                {value}
                                        </MenuItem>
                                    ))}

                            </Select>
                            
                        </FormControl>


                        <RegularButton 
                            text="Filter" 
                            icon={<SearchIcon/>}
                            onClick={() => {
                                filter(traitType, traitValue)
                                handler() 
                            }}/>
                    </Stack>
                        

                </DialogContent>
        </Dialog>
    )

}

export default TraitsPanel