import React from 'react'

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Stack } from '@mui/material'
import { BRFTransition } from '../../utils/BRFTransition'


import CloseIcon from '@mui/icons-material/Close'
import BRFTypography from '../BRFTypography'



const ViewNotifications = ({ status, handler }) => { 

    return (
        <Dialog 
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}
            fullScreen>

            <AppBar sx={{ position: 'relative'}}>

                <Toolbar>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">
                            <CloseIcon/>
                    </IconButton>

                </Toolbar>
            </AppBar>


            <DialogContent
                sx={{ backgroundColor: '#0f111f', maxWidth: '100%'}}>

                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography
                        text="Notification Feature Under Maintenance"/>

                </Stack>

            </DialogContent>

        </Dialog>
    )
}

export default ViewNotifications