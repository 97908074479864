

import FREEMINTBUNDLELIST from './freeMintBundleList.json'
import FREEMINTLIST from './freeMintList.json'
import ALLOWLIST from './allowList.json'
import { getConnectedWallet } from '../WalletSession'


export const freemintbundleList = FREEMINTBUNDLELIST.fmwbhiteList
export const freemintList = FREEMINTLIST.fmwhiteList
export const allowList = ALLOWLIST.whiteList

export const getFreeMintProof = () => { 
   const selectedWallet = freemintList.find((address) => address.address == getConnectedWallet())
   if(selectedWallet){
      return selectedWallet.proof 
   }
}

export const getFreeMintBundleProof = () => { 
   const selectedWallet = freemintbundleList.find((address) => address.address == getConnectedWallet())
   if(selectedWallet){
      return selectedWallet.proof 
   }
}

export const getAllowListProof = () => { 
   const selectedWallet = allowList.find((address) => address.address == getConnectedWallet())
   if(selectedWallet){
      return selectedWallet.proof 
   }
}