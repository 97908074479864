import { AppBar, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import BRLogo from '../../logos/Bullrush_Logo.png'
import { RegularButton } from '../../components/BRFButtons';
import { Box, Stack } from '@mui/system';
import BullRush_bg from '../../images/bullrush_bg.jpg'
import { Link } from 'react-router-dom';

const Logo = styled('img')({
  height: '100px',
  margin: '0 auto'
});

export default function VisionDialog({ open, onClose }) {
   
  return (
    <Dialog open={open} onClose={onClose} fullScreen >
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Our Vision
          </Typography>
          
        </Toolbar>
      </AppBar>

      <DialogContent
            display="flex" 
            
            sx={{ backgroundImage: `url(${BullRush_bg})`, maxWidth: '100%', backgroundSize: 'cover',
            backgroundColor: 'rgba(0,0,0,0.9)',
            backgroundBlendMode: 'multiply',}}
            >

        
        <Stack alignItems="center" spacing={2}>

        <Logo src={BRLogo} alt="Logo" sx={{ marginBottom: 2 }} />


      <Typography color="white" fontFamily="Roboto slab"  sx={{ p: 3, textAlign: 'center', fontSize: {md: "20px", lg: '25px'} }}>
        Our vision is to expand the charitable impact of NFL athletes through consumer 
        participation in our fantasy football game.
        We aim to cultivate relationships and engagement with various leagues globally.
        We plan to reshape the fantasy football landscape, incorporating the excitement of RPGs,
        the investment potential of NFTs, and the community aspect of charity.
        We believe Bull Rush Football will have a significant impact on the way
        we play fantasy football, and we are excited to take this journey with our community.
      </Typography>
        <a 
            href="https://app.gitbook.com/o/KIzeR2vUadV30RoYqWck/s/cV0yOtEFtn6VL0Oy51S5/introduction-and-why" 
            target="_blank">
            <RegularButton
                name="whitepaper"
                id="whitepaper"
                variant="contained"
                
                sx={{ mt: 2 }}
                text="Read Whitepaper"
            /> 
        </a>
      </Stack>
      </DialogContent>
    </Dialog>
  );
}