/*

    ContractPanel.js displays the listed contract to be sorted inorder to display tokens of selected contract 
    on the marketplace

*/


import React, { useState }from 'react'
import { Dialog, DialogContent, FormControl, InputLabel }from '@mui/material'


import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'


import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import { BRFTransition } from '../../utils/BRFTransition'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { RegularButton } from '../BRFButtons'


const theme = createTheme({

    palette: {
      primary: {
        main: '#66fcf1', 
        dark: '#66fcf1', 
        contrastText: '#fff',
        background: '#fff'
      },
  
    }, 
   
})



/* 
    TODO -> retrieve all contracts on api and append to  a usestate contractContainer
            instead of a static array 
*/
const contractContainer = [ 
    process.env.REACT_APP_CONTRACT_ADDRESS
]

const ContractPanel = ({ status, handler, contract}) => { 


    const [contractValue, setContractValue] = useState("")

    const handleContractValue = (event) => { 

        let value = event.target.value 
        if(contractContainer.includes(value)){
            setContractValue(value)
        }
    }

    return ( 
        <Dialog 
            fullWidth
            open={status} 
            onClose={handler}
            TransitionComponent={BRFTransition}>

            <AppBar sx={{ position: 'relative'}}>
                <Toolbar>

                    <IconButton 
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">
                            <CloseIcon/>
                    </IconButton>

                </Toolbar>
            </AppBar>

            <DialogContent
                sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                
                <Stack
                    direction="column"
                    spacing={2} 
                    justifyContent="center"
                    alignItems="center" 
                    display="flex">

                    <ThemeProvider theme={theme}>

                    <FormControl fullWidth>

                        <InputLabel id="contract_label" sx={{ color: '#66fcf1'}}>Smart Contract</InputLabel>

                        <Select 
                            sx={{ 
                                borderColor: '#66fcf1', backgroundColor:'#2b2d55', color: '#66fcf1'
                            }}
                            labelId="contract_label"
                            id="contract_select"
                            value={contractValue}
                            label="Smart Contract"
                            onChange={handleContractValue}>

                                {contractContainer.map( (contract_data, key) => (
                                    <MenuItem 
                                        sx={{ backgroundColor: '#0f111f', color: '#66fcf1', ":hover": { backgroundColor: '#2b2d55'}}}
                                        key={key} 
                                        value={contract_data}>
                                            {contract_data}
                                    </MenuItem>
                                ))}

                        </Select>

                    </FormControl>
                    </ThemeProvider>


                    <RegularButton 
                            text="Sort" 
                            icon={<SearchIcon/>}
                            onClick={() => {
                                contract(contractValue)
                                handler() 
                            }}/>

                </Stack>

            </DialogContent>

        </Dialog>
    )
}

export default ContractPanel 