


import CONTRACT_FILE from '../../utils/DynamicNfts.json'
import { disconnectWallet, estimateGas, getConnectedProvider, getConnectedWallet } from '../WalletSession'
import { activateSession, handleExistingOrNewUser } from '../WalletSession'

import { ethers } from 'ethers'

import Torus from "@toruslabs/torus-embed"
import { getAllowListProof, getFreeMintBundleProof, getFreeMintProof } from './WhiteList'





class TorusController { 

  constructor() { 
      this.torusProvider = null 
      this.web3Provider = null 
      this.contract = null 
      this.buildEnv = "production"
      this.networkName = process.env.REACT_APP_NETWORK_NAME
      this.host = process.env.REACT_APP_TORUS_HOST
      this.chainId = process.env.REACT_APP_NETWORK_CHAIN 
      this.contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
      this.contractABI = CONTRACT_FILE.abi 
      this.providerId = "Torus"
  }

  handleConnect = async () => {
      console.log(this.host);
      console.log(this.networkName)
      console.log(this.chainId)
      this.torusProvider = new Torus({})
      await this.torusProvider.init({
        buildEnv: this.buildEnv, 
        enableLogging: true, 
        network: {
          host: this.host,
          chainId: this.chainId,
          networkName: this.networkName 
        },
        showTorusButton: true,
        enabledVerifiers: {
          facebook: false, 
        },
      })

      await this.torusProvider.login() 
      await this.initializeProvider()
  }

  initializeProvider = async () => { 
      if(!this.torusProvider){
        console.log('Error with Torus Provider')
        return 
      }
      this.web3Provider = new ethers.providers.Web3Provider(this.torusProvider.provider)
      const accounts = await this.web3Provider.listAccounts()
      const address = accounts[0]

      const { chainId } = await this.web3Provider.getNetwork()
      activateSession(address, chainId, this.providerId)
      await handleExistingOrNewUser()
  }

  isFreeBundleMintClaimed = async () => { 
    if(!this.contract){
        this.web3Provider = new ethers.providers.Web3Provider(ethereum)
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
    }
    const bundleClaimStatus = await this.contract.isFreeBundleMintClaimed(getConnectedWallet())
    return bundleClaimStatus
  }

  isFreeMintClaimed = async () => { 
    if(!this.contract){
        this.web3Provider = new ethers.providers.Web3Provider(ethereum)
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
    }
    const bundleClaimStatus = await this.contract.isFreeMintClaimed(getConnectedWallet())
    return bundleClaimStatus
  }

  isAllowListClaimed = async () => { 
    if(!this.contract){
        this.web3Provider = new ethers.providers.Web3Provider(ethereum)
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
    }
    const bundleClaimStatus = await this.contract.isAllowListClaimed(getConnectedWallet())
    return bundleClaimStatus
  }

  bundleSaleStatus = async () => { 
    if(!this.contract){
        this.web3Provider = new ethers.providers.Web3Provider(ethereum)
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
    }
    const bundleMintStatus = await this.contract.bundleSaleStatus()
    return bundleMintStatus
  }

  leaseCard = async (ownerAddress, leaserAddress, expires, tokenId, amount) => { 
    if(!this.contract){
        this.web3Provider = new ethers.providers.Web3Provider(ethereum)
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
    }

    let leaseStatus = { 
        fullMessage: '',
    }

    let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

    await this.contract.leaseAgreement(ownerAddress, leaserAddress, expires, tokenId, {
        maxFeePerGas, 
        maxPriorityFeePerGas,
        value: amount 
    })
    .then((tx) => { 
        this.web3Provider.waitForTransaction(tx.hash)
        leaseStatus['leaser'] = tx
    })
    .catch((error) => { 
        console.error('Minting Error..', error.reason)
        if(error.reason){
            leaseStatus['fullMessage'] = error.reason        
        }else{
            leaseStatus['fullMessage'] = error.code 
        }
    })
    
    return leaseStatus 
}

  handleMint = async (matic, quantity) => { 
      if(!this.torusProvider){
        await this.handleConnect()
      } 
      let mintStatus = { 
        minted: false, 
        message: '',
        action: false 
      }

      if(getConnectedProvider() == this.providerId){

        await this.torusProvider.ethereum.enable()
        
        const signer = this.web3Provider.getSigner()
        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()
        
        await this.contract.mint(getConnectedWallet(), quantity, {
          maxFeePerGas, 
          maxPriorityFeePerGas,
          value: matic 
        })
        .then((tx) => { 
          this.web3Provider.waitForTransaction(tx.hash)
          mintStatus['minted'] = true 
          mintStatus['action'] = true 
          mintStatus['message'] = "Successfull Mint"
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            mintStatus['minted'] = false
            mintStatus['action'] = true 
            mintStatus['message'] = "Error Minting "
            if(error.reason){
                mintStatus['fullMessage'] = error.reason        
            }else{
                mintStatus['fullMessage'] = error.code 
            }
        })
        return mintStatus
      }
  }


  handleMintBundle= async (price) => { 
    if(!this.torusProvider){
      await this.handleConnect()
    } 
    let mintStatus = { 
      minted: false, 
      message: '',
      action: false 
    }

    if(getConnectedProvider() == this.providerId){

      await this.torusProvider.ethereum.enable()
      
      const signer = this.web3Provider.getSigner()
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

      let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()
      
      await this.contract.mintBundle(getConnectedWallet(), {
        maxFeePerGas, 
        maxPriorityFeePerGas,
        value: price 
      })
      .then((tx) => { 
        this.web3Provider.waitForTransaction(tx.hash)
        mintStatus['minted'] = true 
        mintStatus['action'] = true 
        mintStatus['message'] = "Successfull Mint"
      })
      .catch((error) => { 
          console.error('Minting Error..', error)
          mintStatus['minted'] = false
          mintStatus['action'] = true 
          mintStatus['message'] = "Error Minting "
          if(error.reason){
              mintStatus['fullMessage'] = error.reason        
          }else{
              mintStatus['fullMessage'] = error.code 
          }
      })
      return mintStatus
    }
}

  handleFreeMint = async (price) => { 
    if(!this.torusProvider){
      await this.handleConnect()
    } 
    let mintStatus = { 
      minted: false, 
      message: '',
      action: false 
    }

    if(getConnectedProvider() == this.providerId){

      await this.torusProvider.ethereum.enable()
      
      const signer = this.web3Provider.getSigner()
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

      let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()
      
      const proof = getFreeMintProof()

      await this.contract.freeMint(getConnectedWallet(), proof, {
        gasLimit: 300000,
        maxFeePerGas, 
        maxPriorityFeePerGas,
        value: price,
      })
      .then((tx) => { 
        this.web3Provider.waitForTransaction(tx.hash)
        mintStatus['minted'] = true 
        mintStatus['action'] = true 
        mintStatus['message'] = "Successfull Mint"
      })
      .catch((error) => { 
          console.error('Minting Error..', error.reason)
          mintStatus['minted'] = false
          mintStatus['action'] = true 
          mintStatus['message'] = "Error Minting "
          if(error.reason){
              mintStatus['fullMessage'] = error.reason        
          }else{
              mintStatus['fullMessage'] = error.code 
          }
      })
      return mintStatus
    }
  }

  handleFreeMintBundle = async (price) => { 
    if(!this.torusProvider){
      await this.handleConnect()
    } 
    let mintStatus = { 
      minted: false, 
      message: '',
      action: false 
    }

    if(getConnectedProvider() == this.providerId){

      await this.torusProvider.ethereum.enable()
      
      const signer = this.web3Provider.getSigner()
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

      let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()
      
      const proof = getFreeMintBundleProof() 

      await this.contract.freeMintBundle(getConnectedWallet(), proof, {
        gasLimit: 300000,
        maxFeePerGas, 
        maxPriorityFeePerGas,
        value: price,
      })
      .then((tx) => { 
        this.web3Provider.waitForTransaction(tx.hash)
        mintStatus['minted'] = true 
        mintStatus['action'] = true 
        mintStatus['message'] = "Successfull Mint"
      })
      .catch((error) => { 
          console.error('Minting Error..', error.reason)
          mintStatus['minted'] = false
          mintStatus['action'] = true 
          mintStatus['message'] = "Error Minting "
          if(error.reason){
              mintStatus['fullMessage'] = error.reason        
          }else{
              mintStatus['fullMessage'] = error.code 
          }
      })
      return mintStatus
    }
  }


  handleAllowListMintBundle = async (price) => { 
    if(!this.torusProvider){
      await this.handleConnect()
    } 
    let mintStatus = { 
      minted: false, 
      message: '',
      action: false 
    }

    if(getConnectedProvider() == this.providerId){

      await this.torusProvider.ethereum.enable()
      
      const signer = this.web3Provider.getSigner()
      this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

      let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()
      
      const proof = getAllowListProof() 

      await this.contract.allowListMint(getConnectedWallet(), proof, {
        gasLimit: 300000,
        maxFeePerGas, 
        maxPriorityFeePerGas,
        value: price,
      })
      .then((tx) => { 
        this.web3Provider.waitForTransaction(tx.hash)
        mintStatus['minted'] = true 
        mintStatus['action'] = true 
        mintStatus['message'] = "Successfull Mint"
      })
      .catch((error) => { 
          console.error('Minting Error..', error.reason)
          mintStatus['minted'] = false
          mintStatus['action'] = true 
          mintStatus['message'] = "Error Minting "
          if(error.reason){
              mintStatus['fullMessage'] = error.reason        
          }else{
              mintStatus['fullMessage'] = error.code 
          }
      })
      return mintStatus
    }
  }



}

export default TorusController



