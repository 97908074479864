
import React, { useState }from 'react'
import { Dialog, DialogContent }from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip'

import { BRFTransition } from '../utils/BRFTransition'
import BRFTypography from './BRFTypography'
import { isMobile } from '../utils/MobileUtils'


import LogoLoader from './LogoLoader'
import matic_icon from '../logos/matic_logo.png'



const ViewTransactions = ({ status, handler, transaction }) => {

    return (
        <Dialog 
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative'}}>

                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={handler}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>


                        <BRFTypography text={"My Transaction"} />

                       

                    </Toolbar>

                </AppBar>


                {transaction == undefined && 
                 <DialogContent 
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>

                        <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            display="flex">

                                <BRFTypography text={'Error Loading Transaction Details'} variant="h6"/>

                        </Stack>
                        
                </DialogContent>
                }
                <DialogContent 
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>

                        <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            display="flex">


                                <Stack 
                                    direction="column" 
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex">

                                    <BRFTypography
                                        text={"Transaction ID"}/>
                                        
                                    <Chip 
                                        sx={{
                                            color: '#66fcf1'
                                        }}
                                        variant="outlined"
                                        size="sm"
                                        label={`ID # ${transaction.id}`}/>

                                    <BRFTypography
                                        text={"Sender"}/>
                                    <div style={{ 
                                                overflow: "hidden", textOverflow: "ellipsis",
                                                width: '15rem', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                        <Chip 
                                            sx={{
                                                color: '#66fcf1'
                                            }}
                                            variant="outlined"
                                            size="sm"
                                            label={transaction.sender}/>

                                    </div>


                                    <BRFTypography
                                        text={"Receiver"}/>
                                        


                                    {isMobile() ? (
                                        <div style={{ 
                                                overflow: "hidden", textOverflow: "ellipsis",
                                                width: '15rem', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                                <Chip 
                                                    sx={{
                                                        color: '#66fcf1'
                                                    }}
                                                    variant="outlined"
                                                    size="sm"
                                                    label={transaction.receiver}/>
                                        </div>
                                    ): (

                                        <Chip 
                                            sx={{
                                                color: '#66fcf1'
                                            }}
                                            variant="outlined"
                                            size="sm"
                                            label={transaction.receiver}/>
                                
                                    )}



                                    <BRFTypography
                                        text={"Transaction Hash"}/>

                                     <div style={{ 
                                                overflow: "hidden", textOverflow: "ellipsis",
                                                width: '15rem', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                        <Chip 
                                            sx={{
                                                color: '#66fcf1'
                                            }}
                                            variant="outlined"
                                            size="sm"
                                            label={transaction.transaction_hash}/>
                                    </div>


                                    <BRFTypography
                                        text={"Transaction Amount"}/>
                                        
                                        <Chip
                                    sx={{
                                            color : '#66fcf1', 
                                            fontSize: '1rem',
                                        }}
                                    variant="outlined"
                                    fontFamily="AZN"
                                    icon={<LogoLoader icon={matic_icon} width={"30px"} height={"25px"}/>                                      }
                                    label={transaction.amount}/>

                                </Stack>

                        </Stack>
                        
                </DialogContent>
        </Dialog>
    )

}

export default ViewTransactions 