import React, { useState } from 'react' 

import { Box, Stack, Tooltip } from '@mui/material'
import { IconButton } from '@mui/material'

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import StorefrontIcon from '@mui/icons-material/Storefront'


import { getCardByID } from '../../session/backend_session/Backend'
import PlaceMarketPanel from './PlaceMarketPanel'


const defaultValues = { 
    name: 'None'
}

const BRFCardActions = ({ tokenId, moveToSquad, moveToReserves, moveToMarket, squadStatus, refreshContainer }) => {

    
    const [cardData, setCardData] = useState(defaultValues)
    

    const [placeMarketStatus, setPlaceMarketStatus] = useState(false) 


    const pullCardData = async () => {
        const data = await getCardByID(tokenId)
        setCardData(data.data)
    }

    const handlePlaceMarket = async () => { 
        await pullCardData()
        setPlaceMarketStatus(!placeMarketStatus)
    }
 

        


    //console.log('BRFCardActions CardData: ', cardData)

    return (
        <Box 
            alignItems="center" 
            justifyContent="center" 
            display="flex">

                <Stack 
                    direction="row"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">


                    {!squadStatus ? (
                        <Stack 
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center">

                            <Tooltip 
                                placement="top-start"
                                title="Move To Squad">
                                <IconButton 
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                            ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                        }} 
                                    size="large"
                                    aria-label="dashboard icon"
                                    color="inherit"
                                    onClick={() => { moveToSquad() }}
                                    
                                    >
                                    <PlaylistAddIcon />
                                </IconButton>
                            </Tooltip>

                            
                            <Tooltip 
                                placement="top-start"
                                title="Place on Market">
                                <IconButton 
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                            ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                        }} 
                                    size="large"
                                    aria-label="place on market"
                                    color="inherit"  
                                    onClick={handlePlaceMarket}                              
                                    >
                                    <StorefrontIcon/>
                                </IconButton>
                            </Tooltip>

                        </Stack>



                    ) : (

                        <Stack 
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center">


                            <Tooltip 
                                placement="top-start"
                                title="Move To Reserves">
                                <IconButton 
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                            ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                        }} 
                                    size="large"
                                    aria-label="dashboard icon"
                                    color="inherit"
                                    onClick={() => {moveToReserves() }}
                                    
                                    >
                                    <PlaylistRemoveIcon />
                                </IconButton>
                            </Tooltip>


                            
                            <Tooltip 
                                placement="top-start"
                                title="Place on Market">
                                <IconButton 
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                            ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                        }} 
                                    size="large"
                                    aria-label="place on market"
                                    color="inherit"         
                                    onClick={handlePlaceMarket}                              
                       
                                    >
                                    <StorefrontIcon/>
                                </IconButton>
                            </Tooltip>

                        </Stack>

                    ) }



                </Stack>

                <PlaceMarketPanel
                    status={placeMarketStatus}
                    handler={handlePlaceMarket}
                    placeToMarket={moveToMarket}
                    data={cardData}
                    refreshContainer={refreshContainer}
                   />


        </Box>
    )
}

export default BRFCardActions