import React, { useEffect, useState } from 'react'

import { Chip } from '@mui/material'


const BRFCardTraitChip = ({trait, onClick}) => {

    const [traitColor, setTraitColor] = useState(null)

    useEffect(() => {

        const checkColorIndicator = () => {
            if(trait == 'Common'){
                setTraitColor('gray')
            }else if(trait == 'Uncommon'){
                setTraitColor('#00cc00')
            }else if(trait == 'Rare'){
                setTraitColor('blue')
            }else if(trait == 'Epic'){
                setTraitColor('purple')
            }else if(trait == 'Legendary'){
                setTraitColor('#ffdb1a')
            }else{
                setTraitColor('purple')
            }

        }

        checkColorIndicator() 
    }, [])


    return (
       
        <Chip 
            sx={{
                fontWeight: 'bolder',
                color: traitColor, borderColor: traitColor,
            }}
            variant="outlined"
            size="sm"
            label={trait}
            clickable
            onClick={onClick}>

        </Chip>

    )
}

export default BRFCardTraitChip