import React, {useState} from 'react' 


import { Dialog, DialogContent, Stack } from '@mui/material' 
import { Typography, Toolbar, AppBar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { RegularButton } from './BRFButtons'

import { BRFTransition } from '../utils/BRFTransition'
import BullCard from './BRFCard/BullCard'


const ResultView = ({status, handler, data}) => {
    
    return (
        <Dialog
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>

                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handler}
                    aria-label="close">

                    <CloseIcon />

                    </IconButton>


                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                        {data.name} 
                    </Typography>




                </Toolbar>
                </AppBar>

                <DialogContent sx={{ 

                        backgroundColor: '#111322',
                        maxWidth: '100%', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        display: 'flex'
                        
                        }}>

                    <Stack 
                        maxWidth={'40vh'}
                        mt={10}
                        direction="column"
                        spacing={10}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">

                            <BullCard data_handler={data}/>

                    </Stack>
                </DialogContent>
        </Dialog>
    )

}

export default ResultView 