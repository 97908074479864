import React, { useState, useEffect } from 'react'
import { Box, Stack, Button, Paper, Grid, CircularProgress, Chip } from '@mui/material'
import MarketActions from '../../components/Marketplace/MarketActions'
import BullCard from '../../components/BRFCard/BullCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import BRFTypography from '../../components/BRFTypography'
import matic_icon from '../../logos/matic_logo.png'
import LogoLoader from '../../components/LogoLoader'
import { getAllMarketedCards, searchMarketTraits, sortContractData, sortMarketData } from '../../session/backend_session/Backend'

const Marketplace = () => {
    const [marketContainer, updateMarketContainer] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const filterContainerByTraits = async (type, value) => {
        const filtering = await searchMarketTraits(type, value)
        updateMarketContainer(filtering.data)
        setPageNumber(1) // Reset page number when filtering
    }

    const sortContainerByValue = async (sorted_value) => {
        await sortMarketData(sorted_value, pageNumber)
            .then((res) => { 
                updateMarketContainer(res.data.results)
                setPageNumber(1) // Reset page number when sorting
            })  
            .catch(() => {
                updateMarketContainer([])
                setIsLoading(false)
            })
    }

    const sortContainerByContract = async (contract_value) => {
        await sortContractData(contract_value, pageNumber)
            .then((res) => { 
                updateMarketContainer(res.data.results)
                setPageNumber(1) // Reset page number when sorting
            })
            .catch(() => {
                updateMarketContainer([])
                setIsLoading(false)
            })
    }

    const loadMarketData = async (page) => {
        setIsLoading(true)
        await getAllMarketedCards(page)
            .then((res) => {
                updateMarketContainer(res.data.results)
                setIsLoading(false)
            })
            .catch(() => {
                updateMarketContainer([])
                setIsLoading(false)
            })
    }

    const handlePageChange = (newPage) => {
        setPageNumber(newPage)
    }

    useEffect(() => {
        loadMarketData(pageNumber)
    }, [pageNumber])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // Ensure the container takes at least the full viewport height
            }}
        >
            <Box mt={-10} maxWidth="100%" justifyContent="center" alignItems="center" display="flex">
                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                    <MarketActions
                        filter_handler={filterContainerByTraits}
                        sort_handler={sortContainerByValue}
                        contract_handler={sortContainerByContract}
                    />

                    {marketContainer.length > 0 ? (
                        <Box
                            flex="1" // Make this box grow to fill available vertical space
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            maxWidth="100%"
                        >
                            {!isLoading ? (
                                <Grid
                                    sx={{ mb: 10, maxWidth: '100%' }}
                                    container
                                    spacing={{ xs: 2, md: 1, lg: 2 }}
                                    columns={{ xs: 1, sm: 4, md: 12 }}
                                    display="flex"
                                    justifyContent={{ xs: 'center', md: 'space-evenly' }}
                                    alignItems="center"
                                >
                                    {marketContainer.map((card, key) => (
                                        <Grid item xs={2} sm={4} md={3} key={key}>
                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                display="flex"
                                                spacing={0}
                                            >
                                                <BullCard data_handler={card} from={'market'} />
                                                <MarketPriceDisplay price={card.market_details.market_price} />
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <CircularProgress color="secondary" />
                            )}
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="200px"
                        >
                            <BRFTypography text={'No Cards Available'} />
                        </Box>
                    )}
                </Stack>

                {/* Pagination buttons (fixed at the bottom) */}
                <div
                    style={{
                        position: 'fixed',
                        bottom: 10, // Adjust the bottom position as needed
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 999, // Adjust the z-index as needed
                    }}
                >
                    <Stack direction="row" spacing={5}>
                        <Button
                            variant="contained"
                            style={{
                                fontWeight: 'bold',
                            }}
                            color="info"
                            onClick={() => handlePageChange(pageNumber - 1)}
                            disabled={pageNumber === 1}
                        >
                            <ArrowBackIosIcon />
                        </Button>

                        <Button
                            variant="contained"
                            style={{
                                fontWeight: 'bold',
                            }}
                            color="success"
                            onClick={() => handlePageChange(pageNumber + 1)}
                            disabled={marketContainer.length === 0}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                    </Stack>
                </div>
            </Box>
        </div>
    )
}

export const MarketPriceDisplay = ({ price }) => {

    const formattedPrice = price >= 1000 
    ? `${(price / 1000).toFixed(1)}k`
    : price;

    return (
        <Chip
            sx={{
                fontWeight: 'bolder',
                color: '#66fcf1',
            }}
            variant="contained"
            size="sm"
            label={formattedPrice}
            icon={<LogoLoader icon={matic_icon} width={'30px'} height={'25px'} />}
        />
    )
}

export default Marketplace
