import { AppBar, Button, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { acceptTermsofAgreement, getTermsAccepted } from '../session/backend_session/Backend';

// const Logo = styled('img')({
//   height: '100px',
//   margin: '0 auto'
// });

export default function Legal({ open, onClose, termsHandler }) {


  const acceptTerms = async () => {
    await acceptTermsofAgreement()
      .then(() => { 
        termsHandler()
      })
    console.log('Terms Accepted');  
  }

  const declineTerms = () => {
    onClose();
  }
  


   
  return (
    <Dialog open={open} onClose={onClose} fullScreen >
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
          Terms of Service

          </Typography>
          
        </Toolbar>
      </AppBar>

      <DialogContent
            display="flex" 
            
            sx={{maxWidth: '100%', backgroundSize: 'cover',
            backgroundColor: '#0f111f',
            }}
            >

        
        <Stack alignItems="center" spacing={2}>



        <Typography color="white" fontFamily="Roboto slab"  
            sx={{ p: 3, textAlign: 'center', fontSize: {md: "20px", lg: '20px'} }}>

            <span style={{fontSize: '1.2em'}}>1. Acceptance of Terms<br /></span>  
            By accessing or using Bull Rush Football's services and platform, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not access or use our services.
            <br />
            <span style={{fontSize: '1.2em'}}>2. Description of Service<br /></span>
            Bull Rush Football offers a platform that combines the world of NFTs with fantasy football gaming. Users can purchase, trade, and lend NFTs, participate in fantasy football tournaments, and potentially win value.
            <br />
            <span style={{fontSize: '1.2em'}}>3. Registration and Account Security<br /></span>
            Users must register to participate. You agree to: Provide accurate, current, and complete information during the registration process. Maintain the security of your password and not disclose it to any third party. Accept responsibility for all activities that occur under your account.
            <br />
            <span style={{fontSize: '1.2em'}}>4. Purchases and Payments<br /></span>
            NFTs can be purchased at equivalent values of .02 Ethereum for a single piece or .05 Ethereum for a pack of 5 pieces. We accept USD, Wrapped Ethereum, and Matic as payment methods. Two-thirds of the 10% commission from trades will be allocated to prize and charity pools.
            <br />
            <span style={{fontSize: '1.2em'}}>5. Gameplay<br /></span>
            Participation in Bull Rush Football requires understanding of its gameplay mechanics, including position scoring, attribute modification, experience points, and lending structures.
            <br />
            <span style={{fontSize: '1.2em'}}>6. Intellectual Property<br /></span>
            All content, including but not limited to, designs, graphics, logos, and digital assets on Bull Rush Football belongs to Bull Rush Football and its licensors. It is protected by copyright and other laws.
            <br />
            <span style={{fontSize: '1.2em'}}>7. Partnerships<br /></span>
            Bull Rush Football may enter partnerships with various entities, including but not limited to health-focused products. Any partnership does not imply endorsement of the partner's products or services.
            <br />
            <span style={{fontSize: '1.2em'}}>8. Limitation of Liability<br /></span>
            Bull Rush Football shall not be liable for any indirect, incidental, special, consequential, or punitive damages or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.
            <br />
            <span style={{fontSize: '1.2em'}}>9. Changes to Terms of Service<br /></span>
            Bull Rush Football reserves the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.
            <br />
            <span style={{fontSize: '1.2em'}}>10. Termination<br /></span>
            Bull Rush Football reserves the right to terminate or suspend your access to the service for any reason, without notice, at its discretion.
            <br />
            <span style={{fontSize: '1.2em'}}>11. Governing Law<br /></span>
            These Terms are governed by and construed in accordance with the laws of the state of North Carolina, without regard to its conflict of law principles.
            <br />
            <span style={{fontSize: '1.2em'}}>12. Contact<br /></span>
            For any questions about these Terms, please contact us at MetaVersus@MetaVersus.LLC.

        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

        <Button
            variant="contained" 
            color="success"
            onClick={() => acceptTerms()}
        >
            Accept
        </Button>

        <Button 
            variant="contained" 
            color="error" 
            sx={{ ml: 2 }}
            onClick={declineTerms}
        >
            Decline
        </Button>

        </Box>
      </Stack>
      </DialogContent>
    </Dialog>
  );
}