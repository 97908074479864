import { AppBar, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import BRLogo from '../../logos/Bullrush_Logo.png'
import { RegularButton } from '../../components/BRFButtons';
import { Box, Stack } from '@mui/system';
import BullRush_bg from '../../images/bullrush_bg.jpg'
import { Link } from 'react-router-dom';

const Logo = styled('img')({
  height: '100px',
  margin: '0 auto'
});

export default function MobileNewsDialog({ open, onClose }) {
   
  return (
    <Dialog open={open} onClose={onClose} fullScreen >
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Upcoming Schedule
          </Typography>
          
        </Toolbar>
      </AppBar>

      <DialogContent
            display="flex" 
            
            sx={{ backgroundImage: `url(${BullRush_bg})`, maxWidth: '100%', backgroundSize: 'cover',
            backgroundColor: 'rgba(0,0,0,0.9)',
            backgroundBlendMode: 'multiply',}}
            >

        
        <Stack alignItems="center" spacing={2}>

        <Logo src={BRLogo} alt="Logo" sx={{ marginBottom: 2 }} />


      <Typography color="white" fontFamily="Roboto slab"  sx={{ p: 3, textAlign: 'center', fontSize: {md: "20px", lg: '25px'} }}>
      <Typography variant="h2" sx={{
                fontWeight: 700,
                fontSize: '2rem',
                mb: 2
            }}>
                September
            </Typography>

            <Typography variant='p'>
                <b>September 20th</b> we will announce the ability to participate in our Pick'em tournament. Free entry.<br/>See <b>  📣│<a href="https://discord.gg/CEC4EgAkk3" target="_blank">announcements on Discord</a></b> for more details!
            </Typography>
            <br/>
            <br/>

            <Typography variant='p' sx={{mt: 2}}>
                <b>September 23rd</b> will be our first recurring space! Topics will be fluid and we will begin with general NFT talk, tips, strategy, as well as specific Bull Rush tactics. The space will occur weekly on Saturdays and our artist @levitatedarts will be a co-host and representing the art space of NFTs!
            </Typography>
            <br/>
            <br/>

            <Typography variant='p' sx={{mt: 2}}>  
                <b>September 24th</b> begins our Pick'em league. I wanted to begin this after our space so we can discuss how to properly participate and answer any questions!
            </Typography>
            <br/>
            <br/>


            <Typography variant='p' sx={{mt: 2}}>
                <b>September 26th </b> Score Day -- Our goal is to have scores calculated by Tuesday until we automate this process.
            </Typography>
            <br/>

            <Typography variant="h2" sx={{
                fontWeight: 700,
                fontSize: '2rem',
                mt: 4,
                mb: 2
            }}>
                October
            </Typography>

            <Typography variant='p'>
                <b>October 3rd</b> Score Day 
            </Typography>
            <br/>
            <br/>

            <Typography variant='p' sx={{mt: 2}}>
                <b>October 10th</b> Score Day
            </Typography>
            <br/>
            <br/>

            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 12th</b> Bull Rush arrives in Atlanta!
            </Typography>   
            <br/>
            <br/>
            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 13th</b> EMBR Lounge Costume Event
            </Typography>   
            <br/>
            <br/>
            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 15th</b> Atlanta Falcons vs Washington Commanders club seat attendance!
            </Typography>   
            <br/>
            <br/>
            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 17th</b> Score Day
            </Typography>  
            <br/>
            <br/> 
            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 18th</b> tentative reveal date
            </Typography> 
            <br/>
            <br/>  
            <Typography  variant='p' sx={{mt: 2}}>  
                <b>October 24th</b> Score Day
            </Typography> 
            <br/>
            <br/>     
            <Typography variant='p' sx={{mt: 2}}>  
                <b>October 31st</b> Halloween! Show us your costumes! Prizes to be announced for celebrating!!
            </Typography>      
      </Typography>
      </Stack>
      </DialogContent>
    </Dialog>
  );
}
