
import React, { useState } from 'react'
import { Chip, Dialog, DialogContent }from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import matic_icon from '../logos/matic_logo.png'

import { BRFTransition } from '../utils/BRFTransition'
import BRFTypography from './BRFTypography'
import LogoLoader from './LogoLoader'

import { RegularButton, DeclineButton } from './BRFButtons'
import { Box } from '@mui/system'
import { isMobile } from '../utils/MobileUtils'



const ViewOffers = ({ status, handler, offer, handleAccept, handleReject }) => {

    const [ confirmOfferStatus, setConfirmOfferStatus ] = useState(false)
    
    const handleConfirmOffer = () => { 
        setConfirmOfferStatus(!confirmOfferStatus)
    }

    return (
        <Dialog 
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative'}}>

                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={handler}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>


                        <BRFTypography text={offer.card_name} />

                        <Box sx={{ flex: 1}}></Box>

                        {!isMobile() && !offer.accepted &&
                            <Stack 
                                direction="row"
                                spacing={2}>

                                <RegularButton text={"Accept"} onClick={handleConfirmOffer}/>
                                <DeclineButton text={"Decline"} colorCode={"red"} onClick={handleReject}/>
                                
                            </Stack> 
                        }


                        {!isMobile() && offer.accepted && 
                            <Stack 
                                direction="column"
                                spacing={2}>

                                    <BRFTypography
                                        text={"Pending Leaser Sign"}/>
                            </Stack>
                        }
                       

                    </Toolbar>

                </AppBar>


                <DialogContent 
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>

                    <Stack
                        mt={5}
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">


                            <Stack
                                direction="column" 
                                spacing={3}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <BRFTypography text={"Lease Request From: "}/>

                                <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 
                                    <Chip 
                                        sx={{ color: '#66fcf1', fontFamily: 'AZN'}}
                                        variant="outlined"
                                        size="lg"
                                        label={offer.offerer}/>   
                                </div>

                            </Stack>

                            <Stack
                                direction="column" 
                                spacing={3}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <BRFTypography text={"Lease Duration"}/>

                                <Chip 
                                    sx={{ color: '#66fcf1', fontFamily: 'AZN'}}
                                    variant="outlined"
                                    size="lg"
                                    label={offer.lease_duration}/>   

                            </Stack>



                            <Stack
                                direction="column" 
                                spacing={3}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <BRFTypography text={"MATIC OFFER AMOUNT: "}/>

                                <Chip 
                                    sx={{ color: '#66fcf1', fontFamily: 'AZN'}}
                                    variant="outlined"
                                    size="lg"
                                    label={offer.offer_price}
                                    icon={<LogoLoader icon={matic_icon} width={"20px"} height={"25px"}/>}/>                                     


                            </Stack>



                            {isMobile() && !offer.accepted &&
                                <Stack 
                                    direction="row"
                                    spacing={2}>
                                        
                                    <RegularButton text={"Accept"} onClick={handleConfirmOffer}/>
                                    <DeclineButton text={"Decline"} colorCode={"red"} onClick={handleReject}/>
                                    
                                </Stack> 
                            }


                            {isMobile() && offer.accepted && 
                                <Stack 
                                    direction="column"
                                    spacing={2}>

                                        <BRFTypography
                                            text={"Pending Leaser Sign"}/>
                                </Stack>
                            }
                    

                    </Stack>
                        
                </DialogContent>


                <Dialog 
                    fullWidth 
                    open={confirmOfferStatus}
                    onClose={handleConfirmOffer}
                    TransitionComponent={BRFTransition}>


                    <DialogContent 
                        sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                        <Stack 
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center" 
                            display="flex">

                                <BRFTypography 
                                    text={"If Accepted, All Other Offers will be destroyed"}
                                />

                                <Stack 
                                    direction="row"
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex">

                                    <RegularButton
                                        text="Confirm"
                                        onClick={() => { 
                                            handleAccept()
                                            handleConfirmOffer()
                                        }}/>

                                    <DeclineButton 
                                        text={"Decline"} 
                                        colorCode={"red"} 
                                        onClick={handleConfirmOffer}/>
                                
                                </Stack>

                        </Stack>
                    </DialogContent>

                </Dialog>
        </Dialog>
    )

}

export default ViewOffers 