import { AppBar, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import BRLogo from '../../logos/Bullrush_Logo.png'
import { Stack, Box } from '@mui/system';
import BullRush_bg from '../../images/bullrush_bg.jpg'
import ReactYouTube from 'react-youtube';
import { useState } from 'react';

const Logo = styled('img')({
  height: '100px',
  margin: '0 auto'
});


  

const videoId = '-1omfLsUaco'



export default function tutorial({ open, onClose }) {
    const [playing, setPlaying] = useState(false);

    const onPlay = () => setPlaying(true);
    const onPause = () => setPlaying(false);

  return (
    <Dialog open={open} onClose={onClose} fullScreen >
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" fontFamily="Roboto slab" sx={{ flexGrow: 1, textAlign: 'center' }}>
            BR Tutorial
          </Typography>

        </Toolbar>
      </AppBar>

      <DialogContent
            display="flex" 

            sx={{ backgroundImage: `url(${BullRush_bg})`, maxWidth: '100%', backgroundSize: 'cover',
            backgroundColor: 'rgba(0,0,0,0.9)',
            backgroundBlendMode: 'multiply',}}
            >


        <Stack alignItems="center" spacing={2}>

        <Logo src={BRLogo} alt="Logo" sx={{ marginBottom: 2 }} />
        <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
            }}
          >
            <ReactYouTube
              videoId={videoId}
              onPlay={onPlay}
              onPause={onPause}
              opts={{
                width: '100%', 
                
              }}
            />
        </Box>


      </Stack>
      </DialogContent>
    </Dialog>
  );
}