import React from 'react'

import { CardMedia } from '@mui/material'

import coming_soon_frame from '../static/game/coming_soon.png'
import { isMobile } from '../utils/MobileUtils'


 const ComingSoon = () => {

    if(isMobile()){
        return(
            <CardMedia 
            
                sx={{ height: '100%', width: '100%', objectFit: 'contain'}}
                component="img"
                src={coming_soon_frame}
                alt="Coming Soon"
                >

            </CardMedia>
        )
    }
    return (
        <CardMedia 
           
            sx={{ height: '100%', width: '100%', objectFit:'contain'}}
            component="img"
            src={coming_soon_frame}
            alt="Coming Soon"
            >

        </CardMedia>
    )
 }

 export default ComingSoon