import React, { useState } from 'react' 

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ControlPointIcon from '@mui/icons-material/ControlPoint'


import { Chip, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { RegularButton } from './BRFButtons'

import { updateMarketPrice } from '../session/backend_session/Backend'
import BRFTypography from './BRFTypography'


const defaultIncrementPrice = parseFloat(0.01)
const responseTimer = ms => new Promise(res => setTimeout(res, ms))



const PriceAdjuster = ({ tokenId, market_id, refresh }) => { 

    const [ price, setPrice ] = useState(defaultIncrementPrice)
    const [ successfullyAdjusted, setSuccessFullyAdjusted ] = useState(false)
    const [ message, setMessage ] = useState('')  
    const [ messageStatus, setMessageStatus] = useState(false)
    const [ manualEdit, setManualEdit ] = useState(false) 


    const handleManualEdit = () => { 
        setPrice(defaultIncrementPrice)
        setManualEdit(!manualEdit)
    }

    const increasePrice = () => { 
        setPrice( (x) => x += defaultIncrementPrice)
    }

    const increasePriceFaster = () => { 
        setPrice( (x) => x += parseFloat(1))
    }

    

    const decreasePrice = () => { 
        setPrice( (x) => x -= defaultIncrementPrice)
    }

    const decreasePriceFaster = () => { 
        setPrice( (x) => x -= parseFloat(1))
    }

    const validateNegatives = () => { 
        if(price <= 0){
            return true 
        }
        return false 
    }

    const convertToMaticFigures = () => { 
        return parseFloat(price).toFixed(2)
    }

    const handlePriceChange = ( event ) => { 

        if(event.target.value == 0 || event.target.value == NaN){
            setPrice(defaultIncrementPrice)
        }

        if(event.type == 'blur' || event.key == 'Enter'){ 
            const rawPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : 0
            const tempPrice = rawPrice === 0 ? 1 : 0 
            setPrice(tempPrice)
        } else if (event.type == 'change'){ 
            const newPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : null 
            setPrice(newPrice)
        }

    }

    const resetHooks = () => { 
        setSuccessFullyAdjusted(false)
        setMessage('')
        setMessageStatus(false)
        setPrice(defaultIncrementPrice)
        setManualEdit(false)
    }

    const handlePriceAjustment = async () => {
        await updateMarketPrice(market_id, convertToMaticFigures(), tokenId)
            .then((res) => { 
                setMessage('Price Adjusted on Market')
                setSuccessFullyAdjusted(true)

            })
            .catch((error) => { 
                if(error.response.data){ 
                    setMessage(error.response.data.message)
                }
            })
        await refresh() 

        await responseTimer(5000)
        resetHooks() 
    }




      console.log('Adjusted Price Converted: ', convertToMaticFigures())
      console.log('Adjusted Token ID: ', tokenId)
    return ( 

        <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center" 
            display="flex">

            <Chip
                sx={{ 
                    color: '#fff'
                }}
                variant="contained"
                size="sm"
                label="( hint: double click to increase faster )"/>


            {!manualEdit ? (
            <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center" 
                display="flex">


                
                

                {!validateNegatives() ? (
                    <Tooltip
                        placement="top-start"
                        title="Decrease Price">
                        <IconButton
                            sx={{ 
                                backgroundColor: '#DE3163',
                                    ":hover": {
                                    backgroundColor: '#DE3163'
                                }
                                }} 
                            size="large"
                            aria-label="edit listed card"
                            color="inherit"
                            onClick={decreasePrice}
                            onDoubleClick={decreasePriceFaster}
                            >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>  
                ): (
                    <Tooltip
                        placement="top-start"
                        title="Decrease Price">
                        <IconButton
                            sx={{ 
                                backgroundColor: '#DE3163',
                                    ":hover": {
                                    backgroundColor: '#DE3163'
                                }
                                }} 
                            size="large"
                            aria-label="edit listed card"
                            color="inherit"
                            disabled 
                            >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>  
                )
                }  

                <Typography
                    variant="h6"
                    fontFamily="AZN"
                    sx={{
                        color: '#66fcf1'
                    }}>

                        {convertToMaticFigures()}
                </Typography>    



                <Tooltip
                    placement="top-start"
                    title="Increase Price">
                    <IconButton
                        sx={{ 
                            backgroundColor: '#66fcf1',
                                ":hover": {
                                backgroundColor: '#66fcf1'
                            }
                            }} 
                        size="large"
                        aria-label="edit listed card"
                        color="inherit"
                        onClick={increasePrice}
                        onDoubleClick={increasePriceFaster}
                        >
                        <ControlPointIcon />
                    </IconButton>
                </Tooltip>                      



            </Stack>
            ): (

                <TextField 
                    sx={{
                        background: '#fff',
                        color: '#000', 
                        
                    }}
                    variant="outlined"
                    type="number"
                    value={parseFloat(price)}
                    onChange={handlePriceChange}
                />

            )}

            
            <Chip
                    sx={{
                        color: '#66fcf1'
                    }}
                    variant="outlined"
                    size="sm"
                    label={'edit manually'}
                    onClick={handleManualEdit}
                />

            {!successfullyAdjusted &&
                <RegularButton
                    text="Ajust"
                    onClick={() => {
                        handlePriceAjustment()
                    }}/>
            }
                        
            <BRFTypography
                text={message}
                variant="h5"/>
                    
        </Stack> 
    )
}

export { 

    PriceAdjuster
}