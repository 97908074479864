
import React, { useState } from 'react'


import { AppBar, Toolbar, Box, Stack } from '@mui/material'
import { Dialog, DialogContent } from '@mui/material' 

import { Chip, Typography} from '@mui/material'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'


import { BRFTransition } from '../../utils/BRFTransition'
import BRFCardTraitChip from './BRFCardTraitsChip'

import { searchTraits } from '../../session/backend_session/Backend'
import SearchDisplay from '../SearchDisplay'

const BRFCardTraits = ({ status, handler, traits }) => {


    //console.log('Card Traits from BRFCardTraits', traits)

    const [searchList, updateSearchList] = useState([]) 
    
    const [searchStatus, setSearchStatus] = useState(false)

    const handleTraitSearchDialog = () => {
        setSearchStatus(!searchStatus)
    }

    const handleTraitLookup = async (trait_type, value) => {
        const data = await searchTraits(trait_type, value)
        updateSearchList(data.data) 
        handleTraitSearchDialog()
    }

    return (

        <Dialog 
            fullWidth
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>


            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">

                        <CloseIcon />

                    </IconButton>


                    <Typography 
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                        fontFamily="Zelot-Light">
                        Traits 
                    </Typography>




                </Toolbar>
            </AppBar>

            
            <DialogContent 
                sx={{ backgroundColor: '#0f111f'}}>

                <Box 
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                        <Stack 
                            direction="column"
                            spacing={2}
                            >


                            {traits.map( (trait, key) => (

                                <Stack 
                                    key={key} 
                                    direction="row" 
                                    spacing={{xs: 1, md: 10}}>
    
                                    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 

                                        <Chip 
                                            sx={{
                                                fontWeight: 'bolder', 
                                                color: '#fff',
                                            }}
                                            variant="outlined"
                                            fontFamily="monospace"
                                            size="sm"
                                            color="primary"
                                            label={trait.trait_type}/>
                                    </div>

                                
                                    <BRFCardTraitChip 
                                        trait={trait.value}
                                        onClick={() => {
                                            handleTraitLookup(trait.trait_type, trait.value)
                                        }}/>

                                </Stack>

                            ))}


                        </Stack>

                </Box>
            </DialogContent>

            <SearchDisplay status={searchStatus} handler={handleTraitSearchDialog} searchList={searchList}/>
        </Dialog>
    )
}

export default BRFCardTraits