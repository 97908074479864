
import BullRush_bg from '../images/bullrush_bg.jpg'

const Background = ({ children }) => {
    return (
      <div style={{
        backgroundImage: `url(${BullRush_bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden'
      }}
    >
          {children} 

      </div>
    );
}

export default Background