import React , { useState } from 'react'

import { Dialog, AppBar, Toolbar, IconButton, DialogContent, Typography } from '@mui/material'
import { Stack, TextField } from '@mui/material' 

import CloseIcon from '@mui/icons-material/Close'


import { DangerButton, RegularButton } from '../BRFButtons'
import { BRFTransition } from '../../utils/BRFTransition'
import { removeFromMarketByID } from '../../session/backend_session/Backend'
 

const VerifyRemove = ({ status, handler, tokenId }) => { 

    const [ isRemoved, setIsRemoved ] = useState(false) 


    const removeFromMarket = async () => { 

        await removeFromMarketByID(tokenId)
            .then(() => { 
                handler() 
            })
            .catch((error) => { 

            })
    }

    


    return ( 
        <Dialog 
            open={status}
            TransitionComponent={BRFTransition}
            keepMounted
            onClose={handler}
            fullWidth>

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => { 
                            handler() 
                        }}
                        aria-label="close"
                    
                    >
                    <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                        Are you Sure ? 
                    </Typography>
                </Toolbar>
            </AppBar>



            <DialogContent

                display="flex"
                sx={{ backgroundColor: '#111321', maxWidth: '100%'}}>



                    <Stack 
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center" 
                        display="flex">


                        <DangerButton
                            text="Yes"
                            onClick={() => { 
                                removeFromMarket() 
                            }}/>
                           
                    


                        <RegularButton
                            text="No"
                            onClick={handler}/>

                    </Stack> 


                  



                        

            </DialogContent>

        </Dialog> 
    )
}

export default VerifyRemove