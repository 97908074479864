import React from 'react'

import Typography from '@mui/material/Typography'


const BRFTypography = ({variant, text}) => {

    return (
        <Typography 
            sx={{ color: '#ffffff'}}
            variant={variant}
            fontFamily="Roboto Slab"
            component="div">
                {text}
        </Typography>
    )
}

export default BRFTypography 