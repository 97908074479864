

import React from 'react'

import {Paper, Grow} from '@mui/material'

import { isMobile } from '../utils/MobileUtils'


const BRFPanel = ({children, shadow}) => {

    if(isMobile()){
        return (
            <Grow 
                in={true}
                style={{ transformOrigin: '0 0 0'}}
                {...(true ? { timeout: 1000} : {} )}>
    
                <Paper 
                    elevation={shadow} 
                    sx={{ 
                            backgroundColor: '#111322', width: { xs: '350px', sm: '70vh', md: '100vh', lg: '100vh'},
                            height:  {xs: '65vh', sm: '65vh', md: ' 65vh'}, maxHeight: {xs: '70vh', md:'70vh'}, borderRadius: '10px', overflow: 'auto'
                        }}>
    
                            {children} 
    
                </Paper>
            </Grow> 
        )        
    }

    return (
        <Grow 
            in={true}
            style={{ transformOrigin: '0 0 0'}}
            {...(true ? { timeout: 1000} : {} )}>

            <Paper 
                elevation={shadow} 
                sx={{ 
                        backgroundColor: '#111322', width: { xs: '350px', sm: '70vh', md: '100vh', lg: '100vh'},
                        height:  {xs: '75vh', sm: '75vh', md: ' 75vh'}, maxHeight: {xs: '75vh', md:'75vh'}, borderRadius: '10px', overflow: 'auto'
                    }}>

                        {children} 

            </Paper>
        </Grow> 
    )
}

export default BRFPanel 