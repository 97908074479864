import React from 'react'

import { Box, Stack, Tooltip } from '@mui/material'
import { IconButton } from '@mui/material'



import WarehouseIcon from '@mui/icons-material/Warehouse'
import ShopIcon from '@mui/icons-material/Shop'

import { RefreshButton } from './BRFButtons'



const TeamControls = ({ reservedHandler, refreshHandler, listedHandler }) => {

    return (
        <Box 
            alignItems="center" 
            justifyContent="center" 
            display="flex">

                <Stack 
                    direction="row"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">

                    <Tooltip 
                        placement="top-start"
                        title="Reserves">
                        <IconButton 
                            sx={{ 
                                backgroundColor: '#37363b',
                                    ":hover": {
                                    backgroundColor: '#9D9CA1'
                                }
                                }} 
                            size="large"
                            aria-label="reserves panel icon"
                            color="inherit"
                            onClick={reservedHandler}
                            >
                            <WarehouseIcon />
                        </IconButton>
                    </Tooltip>


                    <Tooltip 
                        placement="top-start"
                        title="Listed Cards">
                        <IconButton 
                            sx={{ 
                                backgroundColor: '#37363b',
                                    ":hover": {
                                    backgroundColor: '#9D9CA1'
                                }
                                }} 
                            size="large"
                            aria-label="listed cards panel icons"
                            color="inherit"
                            onClick={listedHandler}
                            >
                            <ShopIcon />
                        </IconButton>
                    </Tooltip>

                    <RefreshButton onClick={refreshHandler}/>

                </Stack>


        </Box>
    )
}

export default TeamControls