
import { EthereumProvider } from "@walletconnect/ethereum-provider"
import { activateSession, disconnectWallet, estimateGas, handleExistingOrNewUser } from "../WalletSession"

import { getConnectedWallet } from "../WalletSession"
import { ethers } from "ethers"

import CONTRACT_FILE from '../../utils/DynamicNfts.json'
import { getAllowListProof, getFreeMintBundleProof, getFreeMintProof } from "./WhiteList"




class MobileController{ 

    constructor() { 
        this.mobileProvider = null 

        this.projectId = process.env.REACT_APP_WALLET_CONNECT_ID

        this.contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
        this.contractABI = CONTRACT_FILE.abi 
        this.chainId = process.env.REACT_APP_NETWORK_CHAIN 
        this.providerId = "WalletConnect"

    }
    //        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    handleInitilization = async () => {}

    handleChainChanged = async () => {
        activateSession(this.mobileProvider.accounts[0], this.chainId)
        await handleExistingOrNewUser()
    }

    handleAccountsChanged = async () => {
        activateSession(this.mobileProvider.accounts[0], this.mobileProvider.chainId, this.providerId)
    }


    handleConnect = async () => { 
        this.mobileProvider = await EthereumProvider.init({
            projectId: this.projectId, 
            chains: [this.chainId],
            showQrModal: true 
        })

        if(!this.mobileProvider.connect()){
            await this.mobileProvider.connect({
                chains: [this.chainId]
            })
        }
        await this.listenForEvents()
    }

    handleSessionEvent = async () => {}

    handleDisconnect = async () => {
        console.log('Disconnecting Wallet....[Mobile]')
        disconnectWallet()
    }

    isFreeBundleMintClaimed = async () => { 
        const bundleClaimStatus = await this.contract.isFreeBundleMintClaimed()
        return bundleClaimStatus
    }

    isFreeBundleMintClaimed = async () => { 
        if(!this.contract || !this.mobileProvider){
            await this.handleConnect()
            const provider = new ethers.providers.Web3Provider(this.mobileProvider)
            const signer = provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isFreeBundleMintClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    isFreeMintClaimed = async () => { 
        if(!this.contract || !this.mobileProvider){
            await this.handleConnect()
            const provider = new ethers.providers.Web3Provider(this.mobileProvider)
            const signer = provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isFreeBundleMintClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    isAllowListClaimed = async () => { 
        if(!this.contract || !this.mobileProvider){
            await this.handleConnect()
            const provider = new ethers.providers.Web3Provider(this.mobileProvider)
            const signer = provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleClaimStatus = await this.contract.isAllowListClaimed(getConnectedWallet())
        return bundleClaimStatus
    }

    bundleSaleStatus = async () => { 
        if(!this.contract || !this.mobileProvider){
            await this.handleConnect()
            const provider = new ethers.providers.Web3Provider(this.mobileProvider)
            const signer = provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }
        const bundleMintStatus = await this.contract.bundleSaleStatus()
        return bundleMintStatus
    }

    leaseCard = async (ownerAddress, leaserAddress, expires, tokenId, amount) => { 
        if(!this.contract){
            await this.handleConnect()
            const provider = new ethers.providers.Web3Provider(this.mobileProvider)
            const signer = provider.getSigner()
            this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)        
        }

        let leaseStatus = { 
            fullMessage: '',
        }

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        await this.contract.leaseAgreement(ownerAddress, leaserAddress, expires, tokenId, {
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: amount 
        })
        .then((tx) => { 
            this.web3Provider.waitForTransaction(tx.hash)
            leaseStatus['leaser'] = tx
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            if(error.reason){
                leaseStatus['fullMessage'] = error.reason        
            }else{
                leaseStatus['fullMessage'] = error.code 
            }
        })
        
        return leaseStatus 
    }

    handleMint = async (matic, quantity) => { 
       
        if(this.mobileProvider == null){
            console.log('Connecting Mobile Provider')
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            action: false 
        }

        const provider = new ethers.providers.Web3Provider(this.mobileProvider)
        const signer = provider.getSigner()

        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

       
        await this.contract.mint(getConnectedWallet(), quantity, {
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: matic 
        })
        .then((tx) => { 
            this.web3Provider.waitForTransaction(tx.hash)
            mintStatus['minted'] = true 
            mintStatus['action'] = true 
            mintStatus['message'] = "Successfull Mint"
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            mintStatus['minted'] = false
            mintStatus['action'] = true 
            mintStatus['message'] = "Error Minting "
            if(error.reason){
                mintStatus['fullMessage'] = error.reason        
            }else{
                mintStatus['fullMessage'] = error.code 
            }
        })
        return mintStatus
    }

    handleFreeMint = async (price) => { 
        if(this.mobileProvider == null){
            console.log('Connecting Mobile Provider')
            await this.handleConnect()
        }


        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        const provider = new ethers.providers.Web3Provider(this.mobileProvider)
        const signer = provider.getSigner()

        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        const proof = getFreeMintProof()

        await this.contract.freeMint(getConnectedWallet(), proof, {
            gasLimit: 300000,
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: price,
        })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting (Check Network Chain)"
                mintStatus['fullMessage'] = error.code 
            })
            return mintStatus
    }




    handleFreeMintBundle = async (price) => { 
        if(this.mobileProvider == null){
            console.log('Connecting Mobile Provider')
            await this.handleConnect()
        }


        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        const provider = new ethers.providers.Web3Provider(this.mobileProvider)
        const signer = provider.getSigner()

        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        const proof = getFreeMintBundleProof()

        await this.contract.freeMintBundle(getConnectedWallet(), proof, {
            gasLimit: 300000,
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: price,
        })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting (Check Network Chain)"
                mintStatus['fullMessage'] = error.code 
            })
            return mintStatus
    }


    handleAllowListMintBundle = async (price) => { 
        if(this.mobileProvider == null){
            console.log('Connecting Mobile Provider')
            await this.handleConnect()
        }


        let mintStatus = { 
            minted: false, 
            message: '',
            fullMessage: '',
            action: false 
        }

        const provider = new ethers.providers.Web3Provider(this.mobileProvider)
        const signer = provider.getSigner()

        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

        const proof = getAllowListProof()

        await this.contract.allowListMint(getConnectedWallet(), proof, {
            gasLimit: 300000,
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: price,
        })
            .then((tx) => { 
                this.web3Provider.waitForTransaction(tx.hash)
                mintStatus['minted'] = true 
                mintStatus['action'] = true 
                mintStatus['message'] = "Successfull Mint"
            })
            .catch((error) => { 
                console.error('Minting Error..', error)
                mintStatus['minted'] = false
                mintStatus['action'] = true 
                mintStatus['message'] = "Error Minting (Check Network Chain)"
                mintStatus['fullMessage'] = error.code 
            })
            return mintStatus
    }

    handleMintBundle = async (price) => { 
       
        if(this.mobileProvider == null){
            console.log('Connecting Mobile Provider')
            await this.handleConnect()
        }

        let mintStatus = { 
            minted: false, 
            message: '',
            action: false 
        }

        const provider = new ethers.providers.Web3Provider(this.mobileProvider)
        const signer = provider.getSigner()

        this.contract = new ethers.Contract(this.contractAddress, this.contractABI, signer)

        let { maxFeePerGas, maxPriorityFeePerGas } = await estimateGas()

       
        await this.contract.mintBundle(getConnectedWallet(), {
            gasLimit: 300000,
            maxFeePerGas, 
            maxPriorityFeePerGas,
            value: price,
        })
        .then((tx) => { 
            this.web3Provider.waitForTransaction(tx.hash)
            mintStatus['minted'] = true 
            mintStatus['action'] = true 
            mintStatus['message'] = "Successfull Mint"
        })
        .catch((error) => { 
            console.error('Minting Error..', error.reason)
            mintStatus['minted'] = false
            mintStatus['action'] = true 
            mintStatus['message'] = "Error Minting "
            if(error.reason){
                mintStatus['fullMessage'] = error.reason        
            }else{
                mintStatus['fullMessage'] = error.code 
            }
        })
        return mintStatus
    }


    

    listenForEvents = async () => { 
        
        this.mobileProvider.on('chainChanged', this.handleChainChanged)
        this.mobileProvider.on('connect', this.handleConnect)
        this.mobileProvider.on('accountsChanged', this.handleAccountsChanged)
        this.mobileProvider.on('disconnect', this.handleDisconnect)
    
    }
}

export default MobileController