


import { getAllUsers } from "./Backend"



const checkExistingUsers = async () => {

    const data = await getAllUsers()
    return data.data             
} 

const setAuthTokens = (access, refresh) => {
    localStorage.setItem('access', access)
    localStorage.setItem('refresh', refresh)
}







export {
    checkExistingUsers, 
    setAuthTokens,
}

