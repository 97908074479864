

import React  from 'react'

import Button from '@mui/material/Button' 
import { Link } from 'react-router-dom'


import RefreshIcon from '@mui/icons-material/Refresh'
import { IconButton, Tooltip } from '@mui/material'

const RegularButton = ({text, icon, onClick}) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#66fcf1',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#01dddd',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}

const ConnectButton = ({text, icon, onClick}) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#EA80FC',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#7827E6',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}

const SubmitButton = ({text, icon, onClick}) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#66fcf1',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#01dddd',
                    border: 'none'

                
                }
            }}
            type="submit"
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}



const DeclineButton = ({text, icon, onClick}) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#DE3163',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#d42255',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}

const NavigateButton = ({text, icon, onClick, path, hex }) => {

    return (
        <Button 
            component={Link}  
            to={path} 
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#DE3163',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#d42255',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}



const RefreshButton= ({ onClick }) => {

    return (
        <Tooltip
            placement="top-start"
            title="refresh">
            <IconButton 
                sx={{ 
                    backgroundColor: '#37363b',
                        ":hover": {
                        backgroundColor: '#9D9CA1'
                    }
                    }} 
                size="large"
                aria-label="dashboard icon"
                color="inherit"
                onClick={onClick}
                
                >
                <RefreshIcon/>
            </IconButton>
        </Tooltip>

    )
}




const MintButton= ({icon, onClick, text }) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#00cc00',  color: '#fff', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#00cc00',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}


const MintWhiteListButton= ({icon, onClick, text, disabled}) => {

    return (
        <Button
            disabled={disabled}
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#cc184e',  color: '#fff', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#e84575',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}



const DangerButton = ({icon, onClick, text}) => {

    return (
        <Button
            variant="outlined"
            sx={{ 
                width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                height: '3.5rem', backgroundColor: '#DE3163',  color: '#fff', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                ":hover": { 
                    backgroundColor: '#DE3163',
                    border: 'none'

                
                }
            }}
            endIcon={icon}
            onClick={onClick}>
                {text}
        </Button>

    )
}





export {
    RegularButton, 
    SubmitButton,
    DeclineButton, 
    NavigateButton, 
    MintButton,
    RefreshButton,
    DangerButton,
    ConnectButton,
    MintWhiteListButton,
}