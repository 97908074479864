import React, { useState } from 'react' 

import {Box, Stack, Paper, Button, Typography } from '@mui/material'
import { Grow } from '@mui/material'

import BRFTypography from '../../components/BRFTypography'

import { MintButton, RegularButton } from '../../components/BRFButtons'
import MintDialog from '../../components/mintComponents/MintDialog'
import { isMobile } from '../../utils/MobileUtils'
import MobileNewsDialog from './MobileNewsDialog'
import BullRush_bg from '../../images/bullrush_bg.jpg'



const Dashboard = () => {


    const [ mintDialogStatus, setMintDialogStatus ] = useState(false)
    const [openNews, setOpenNews] = useState(false)



    const handleMintDialog = () => { 
        setMintDialogStatus(!mintDialogStatus)
    }

    const handleNewsDialog = () => { 
        setOpenNews(!openNews)
    }


    return (
        <Box mt={-6}>

            <Stack 
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center">
                <BRFTypography variant="h6" text="Dashboard"/>



                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 1000} : {} )}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                                backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '150vh'}, maxWidth: '150vh',
                                height:  {xs: '650px', sm: '650px', md: ' 75vh'}, maxHeight: '75vh', borderRadius: '10px', overflow: 'auto',
                                justifyContent: 'center', alignItems: 'center', display: 'flex', overflowY: 'auto'
                            }}>

                                <Stack 
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center" 
                                    display="flex">

                                        {isMobile() ? (
                                            <Box> 
                                                <Stack spacing={3}>    
                                                <RegularButton onClick={handleNewsDialog} 
                                                text="Upcoming Schedule"/> 
                                                <MintButton
                                                    text="Mint"
                                                    onClick={handleMintDialog}/>

                                            
                                            

                                                
                                                </Stack>
                                            </Box>
                                        ): (

                                            <Box sx={{ 
                                                maxWidth: 800,
                                                margin: '0 auto',
                                                fontFamily: 'Roboto Slab',
                                                padding: '20px',
                                                borderRadius: '10px',
                                                color: "white"
                                              }}>

                                            <Typography variant="h4" textAlign="center">
                                                Upcoming Schedule
                                            </Typography>
                                              
                                              <Typography variant="h2" sx={{
                                                    fontWeight: 700,
                                                    fontSize: '2rem',
                                                    mb: 2
                                                }}>
                                                    September
                                                </Typography>

                                                <Typography variant='p'>
                                                    <b>September 20th</b> we will announce the ability to participate in our Pick'em tournament. Free entry.<br/>See <b>  📣│<a href="https://discord.gg/CEC4EgAkk3" target="_blank">announcements on Discord</a></b> for more details!
                                                </Typography>
                                                <br/>
                                                <br/>

                                                <Typography variant='p' sx={{mt: 2}}>
                                                    <b>September 23rd</b> will be our first recurring space! Topics will be fluid and we will begin with general NFT talk, tips, strategy, as well as specific Bull Rush tactics. The space will occur weekly on Saturdays and our artist @levitatedarts will be a co-host and representing the art space of NFTs!
                                                </Typography>
                                                <br/>
                                                <br/>

                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>September 24th</b> begins our Pick'em league. I wanted to begin this after our space so we can discuss how to properly participate and answer any questions!
                                                </Typography>
                                                <br/>
                                                <br/>


                                                <Typography variant='p' sx={{mt: 2}}>
                                                    <b>September 26th </b> Score Day -- Our goal is to have scores calculated by Tuesday until we automate this process.
                                                </Typography>
                                                <br/>

                                                <Typography variant="h2" sx={{
                                                    fontWeight: 700,
                                                    fontSize: '2rem',
                                                    mt: 4,
                                                    mb: 2
                                                }}>
                                                    October
                                                </Typography>

                                                <Typography variant='p'>
                                                    <b>October 3rd</b> Score Day 
                                                </Typography>
                                                <br/>
                                                <br/>

                                                <Typography variant='p' sx={{mt: 2}}>
                                                    <b>October 10th</b> Score Day
                                                </Typography>
                                                <br/>
                                                <br/>

                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 12th</b> Bull Rush arrives in Atlanta!
                                                </Typography>   
                                                <br/>
                                                <br/>
                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 13th</b> EMBR Lounge Costume Event
                                                </Typography>   
                                                <br/>
                                                <br/>
                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 15th</b> Atlanta Falcons vs Washington Commanders club seat attendance!
                                                </Typography>   
                                                <br/>
                                                <br/>
                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 17th</b> Score Day
                                                </Typography>  
                                                <br/>
                                                <br/> 
                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 18th</b> tentative reveal date
                                                </Typography> 
                                                <br/>
                                                <br/>  
                                                <Typography  variant='p' sx={{mt: 2}}>  
                                                    <b>October 24th</b> Score Day
                                                </Typography> 
                                                <br/>
                                                <br/>     
                                                <Typography variant='p' sx={{mt: 2}}>  
                                                    <b>October 31st</b> Halloween! Show us your costumes! Prizes to be announced for celebrating!!
                                               </Typography>                                         
                                        </Box>

                                        )}

                                      
                                </Stack>
                                


                                
                    </Paper>
                    
                </Grow>


            </Stack>


            <MintDialog 
                status={mintDialogStatus} 
                handler={handleMintDialog}/>
            <MobileNewsDialog 
                open={openNews}
                onClose={handleNewsDialog} 
            />


        </Box>
    )
}

export default Dashboard 
