

import React from 'react'
import Slide from '@mui/material/Slide'


const BRFTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export {
    BRFTransition
}