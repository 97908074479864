import React, { useRef, useEffect } from 'react'

import trailer from '../../components/videos/bullrush_vid.mp4'

import {Carousel} from '3d-react-carousal';
import './homescreen.css'


import Bull1 from '../../images/bull1.png'
import Bull2 from '../../images/bull2.png'
import Bull3 from '../../images/bull3.png'
import Bull4 from '../../images/bull4.png'
import Bull5 from '../../images/bull5.png'
import Bull6 from '../../images/bull1.png'
import Bull7 from '../../images/bull2.png'
import Bull8 from '../../images/bull3.png'
import Bull9 from '../../images/bull4.png'




// MUI Imports 

import {Box, Paper, Stack, Typography, Grow, Button} from '@mui/material' 

import GetStarted from './GetStarted'
import DiscoverBullRush from './Present'
import BRFPanel from '../../components/BRFPanel';
import { isMobile } from '../../utils/MobileUtils';



const HomeScreen  = () => {


    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [videoRef]);

    let slides = [
        <img  
        src={Bull1} 
        alt="1" 
        className="image"
        style={{ border: "none" }} />,

        <img 
        src={Bull2} 
        alt="2" 
        className="image"
        style={{ border: "none" }} />,

        <img 
        src={Bull3} 
        alt="3" 
        className="image"
        style={{ border: "none" }} />,

        <img  
        src={Bull4} 
        alt="4" 
        className="image"
        style={{ border: "none" }} />,

        <img  
        src={Bull5} 
        alt="5" 
        className="image"
        style={{ border: "none" }} />,

        <img 
        src={Bull6} 
        alt="6" 
        className="image"
        style={{ border: "none" }} />,

        <img  
        src={Bull7} 
        alt="7" 
        className="image"
        style={{ border: "none" }} />,

        <img  
        src={Bull8} 
        alt="8" 
        className="image"
        style={{ border: "none" }} />,

        <img  
        src={Bull9} 
        alt="9" 
        className="image"
        style={{ border: "none" }} />,
     ];


    return (
        
        <Box 
            width={{xs: '100%', md: "60%"}} 
            height="100%" 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            flexDirection="column"
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0, 0.9)',
                mt: {xs: 20, sm: 13},
                mb: {xs: 0, sm: 40}
            }}>

            
                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 3000} : {} )}>
                    <Paper
                        elevation={10}
                        height=  '100vh'
                        sx={{ 
                            backgroundColor: 'transparent', 
                            width: '100%', 
                            height:  '100%',
                            position: 'relative', 
                            boxShadow: '0', 
                        }}>
                            <div className="slider-single" >
                                <div className="react-3d-carousel slider-container slider-content slider-single slider-single-content">
                                    <div className='carousel-container'>
                                        <Carousel 
                                            slides={slides} 
                                            autoplay 
                                            interval={2000} 
                                            width="100%" 
                                            boxShadow="0" 
                                            margin={10} 
                                            className="hide-navigation-arrows" 
                                            style={{border: "none"}}
                                            slideStyle={{border: "none"}} />
                                    </div>
                                </div>
                            </div>
                    </Paper>
                </Grow>    

              

                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 1000} : {} )}>
                    <Paper
                        elevation={10}
                        height=  '100vh'
                        sx={{ 
                            backgroundColor: 'transparent', 
                            width: '100%', 
                            height:  '100%',
                            position: 'relative', 
                            boxShadow: '0', 
                        }}>

                            <Stack 
                                direction="column"
                                spacing={10}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <Typography
                                    fontFamily={"DiloWorld"}
                                    fontSize={{xs : '1.8rem', sm: '2rem', md: '3rem'}}
                                    fontWeight={"bold"}
                                    color="#fff">
                                        BULLRUSH FOOTBALL 
                                </Typography>

                            </Stack>
                          
                    </Paper>
                </Grow>

              


                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 2000} : {} )}>
                    <Paper
                        elevation={10}
                        height=  '100vh'
                        sx={{ 
                            backgroundColor: 'transparent', 
                            width: '100%', 
                            height:  '100%',
                            position: 'relative', 
                            boxShadow: '0', 
                            paddingTop: 5,
                        }}>

                            <Stack 
                                mt={{ xs: 0, sm: 10}}
                                direction="column"
                                spacing={{ xs: 2, md: 10}}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                    <DiscoverBullRush/>

                            </Stack>

                    </Paper>
                </Grow>


        </Box>
    )
}

export default HomeScreen 