

import React, { useState } from 'react'

import { Card } from '@mui/material'
import BRFCardMedia from './BRFCardMedia'
import { isMobile } from '../../utils/MobileUtils'



const FrontCard = ({ handler, card_image_uri}) => {


    if(isMobile()){
        return (
            <Card 
                sx={{ 
                    height: `410px`,
                    cursor: 'pointer',
                    margin: '1px 4px 0px 0px',
                    opacity: { xs: '1', sm: '1', md: '1', lg: '1'},
                    ":hover": {
                        opacity: '1', 
                    },
                    

                }}
                onClick={handler}>

                <BRFCardMedia
                    ipfs_uri={card_image_uri}/>
        
            </Card>
        )
    }
    return (
        <Card
                sx={{ 
                    height: '50vh',
                    width: { xs: '317px', md: '264px' },
                    cursor: 'pointer',
                    margin: '1px',
                    opacity: { xs: '1', sm: '1', md: '1', lg: '1'},
                    ":hover": {
                        opacity: '1', 
                    }

                }}
                onClick={handler}>

                <BRFCardMedia
                    ipfs_uri={card_image_uri}/>
            

        </Card>

    )
}

export default FrontCard 