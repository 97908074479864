import React, { useEffect, useState } from 'react'

import { Dialog, DialogContent, Stack, Grid} from '@mui/material'
import { AppBar, Toolbar } from '@mui/material'

import { IconButton , Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import TeamPanel from './TeamPanel'
import BRFTypography from './BRFTypography'
import BRFPanel from './BRFPanel'
import BullCard from './BRFCard/BullCard'
import BRFCardActions from './BRFCard/BRFCardActions'

import { MintButton, RefreshButton } from './BRFButtons'
import { BRFTransition } from '../utils/BRFTransition'


import { getActiveSquadCount, getReservedCards, pushToMarketByID } from '../session/backend_session/Backend'
import { pushToActive } from '../session/backend_session/Backend'
import BRFAlert from './BRFAlert'

import MintDialog from './mintComponents/MintDialog';





const ReservedSquad = ({ status, handler }) => {


    const [reservedCards, setReservedCards] = useState([]) 
    

    const [alertStatus, setAlertStatus] = useState(false)
    const [alertIndicator, setAlertIndicator] = useState()
    const [alertMessage, setAlertMessage] = useState()
    
    const [mintDialogStatus, setMintDialogStatus] = useState(false);

    const handleMintDialog = () => {
        console.log('Toggling mintDialogStatus');
        setMintDialogStatus(!mintDialogStatus);
    };

    const handleAlert = (reason) => {
        if(reason == 'clickaway'){
            return 
        }

        setAlertStatus(true)
    }

    const displayAlert = (indicator, message) => {

        setAlertStatus(true)
        setAlertMessage(message)
        setAlertIndicator(indicator)
    }

    const moveToSquad = async (tokenId) => {


        // if players counts
        let players_count = await getActiveSquadCount()
        console.log('Current Squad Players: ', players_count.data)
        if(players_count.data < 5){

            await pushToActive(tokenId)
                    .then(() => {
                        console.log('Pushing to Active Squad')
                        displayAlert('info', 'Pushing to Active Squad')
                        refreshReservedData() 
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    displayAlert('info', 'Card Data Refreshed')
                    console.log('Card Data Refreshed')
                    return 
            
        }

        console.log('Squad is Full')
        displayAlert('info', 'Squad is Full')

    }

    const moveToMarket = async ( market_id, market_price ) => { 
        await pushToMarketByID(market_id, market_price)
                .then(() => { 
                    console.log('Pushing to Market')
                    refreshReservedData() 
                })
                .catch((error) => { 
                    console.error(error)
                })
    }



    const refreshReservedData = async () => {

        displayAlert('info', 'Refreshing......')

        const squad_data = [] 
            const data = await getReservedCards()
            
            if(data.data){
                const reserved = data.data.reserved 
                const leased = data.data.leased 

                reserved.map((token) => {
                    squad_data.push(token)
                })
                leased.map((token) => { 
                    squad_data.push(token)
                })

                setReservedCards(squad_data)
                
        }

        displayAlert('info', 'Squad Refreshed')
        console.log('Refreshing.............')

    }




    useEffect(() => {
        
        const mountReservedCards = async () => {
            const squad_data = [] 
            const data = await getReservedCards()
            
            if(data.data){
                const reserved = data.data.reserved 
                const leased = data.data.leased 

                reserved.map((token) => {
                    squad_data.push(token)
                })
                leased.map((token) => { 
                    squad_data.push(token)
                })

                setReservedCards(squad_data)
                
            }
        }

    
        mountReservedCards() 

    },[])


    console.log('Card Data: ', reservedCards)
    //console.log('Data LENGTH:  ', reservedCards.length)

    return (

        <Dialog 
            open={status}
            TransitionComponent={BRFTransition}
            keepMounted
            onClose={handler}
            fullScreen>

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close"
                    
                    >
                    <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                        Reserved Squad 
                    </Typography>
                </Toolbar>
            </AppBar>



            <DialogContent

                display="flex"
                sx={{ backgroundColor: '#111321', maxWidth: '100%'}}>

                    <Stack 
                        mt={2}
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">

                        {reservedCards.length == 0 ? (

                                <BRFPanel>
                                    <Stack 
                                        mt={10}
                                        direction="column"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        display="flex">
                                            <BRFTypography text={"No Bull Cards Available"}/>

                                            <Stack
                                                direction="row"
                                                spacing={3}>

                                                    <MintButton 
                                                        text="Mint" 
                                                        onClick={handleMintDialog} 
                                                    />

                                                    <MintDialog
                                                        status={mintDialogStatus}
                                                        handler={handleMintDialog}
                                                    />
                                                    
                                                    <RefreshButton 
                                                        text="Refresh Squad"
                                                        onClick={refreshReservedData}
                                                    />
                                            </Stack>
                                    </Stack>
                                </BRFPanel>

                            ) :(
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex">


                                    <Stack
                                        direction="row"
                                        spacing={3}>

                                        
                                        <RefreshButton 
                                            text="Refresh Squad"
                                            onClick={refreshReservedData}/>
                                    </Stack>
                                    


                                    <TeamPanel> 

                                    

                                           
                                
                                    <Grid 
                                        sx={{ mb: 10, maxWidth: "100%" }}
                                        container 
                                        spacing={{ xs: 2, md: 1, lg: 2}}
                                        columns={{ xs: 1, sm: 4, md: 12 }}
                                        display="flex"
                                        justifyContent={{xs: 'center', md: 'space-evenly'}}
                                        alignItems="center">
                                            {reservedCards.map(( card, key) => (
                                                <Grid 
                                                    item 
                                                    xs={2}
                                                    sm={4}
                                                    md={4} 
                                                    key={key}>

                                            <Stack
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                display="flex"
                                                spacing={0}
                                            >

                                                        <BullCard 
                                                            data_handler={card}
                                                            from={"team"}/> 

                                                        


                                                            <BRFCardActions 
                                                                tokenId={card.tokenId}
                                                                moveToSquad={ () => {moveToSquad(card.tokenId)}}
                                                                moveToMarket={moveToMarket} 
                                                                refreshContainer={refreshReservedData}
                                                            />

                                                        </Stack>

                                                </Grid>
                                            ))}
                                        </Grid>

                                    </TeamPanel>
                                </Stack>

                        )}
                    </Stack>

                    <BRFAlert
                        status={alertStatus}
                        handler={handleAlert}
                        indicator={alertIndicator}
                        message={alertMessage}/>


            </DialogContent>
        </Dialog>


    )
}

export default ReservedSquad