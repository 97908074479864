import React, { useEffect, useState } from 'react' 

import {Box, Stack, Grow, Tooltip, IconButton} from '@mui/material'
import {Card, CardContent} from '@mui/material'
import { Typography } from '@mui/material'

import MailOutlineIcon from '@mui/icons-material/MailOutline'


import BRFPanel from '../../components/BRFPanel'
import ViewOffers from '../../components/ViewOffers'
import { RefreshButton, RegularButton } from '../../components/BRFButtons'
import { acceptOffer, getOffers, getSentOffers, rejectOffer } from '../../session/backend_session/Backend'
import OffersSent from '../../components/leasing/OffersSent'
import BRFTypography from '../../components/BRFTypography'





const Offers = () => {


    const [offer, setOffer] = useState({}) 
    const [offerStatus, setOfferStatus] = useState(false)
    const [offerSentStatus, setOfferSentStatus] = useState(false)

    const [offerSentContainer, updateOfferSentContainer] = useState([])
    const [offersContainer, updateOffersContaner] = useState([])
   


    const handleViewOffer = () => {
        setOfferStatus(!offerStatus)
    }

    const handleOfferSent = () => {
        setOfferSentStatus(!offerSentStatus)
        refreshData() 
    }

    const refreshData = async () => {
        const offers_data = await getOffers() 
        const offers_sent = await getSentOffers() 
        updateOffersContaner(offers_data.data) 
        updateOfferSentContainer(offers_sent.data)
    }

    const handleAcceptOffer = async (offer_id, card_id) => {
        await acceptOffer(offer_id, card_id)
        await refreshData() 
        handleViewOffer() 
    }

    const handleRejectOffer = async ( id ) => {
        await rejectOffer(id)
        await refreshData()
        handleViewOffer() 
    }




  

    useEffect(() => {

        const mountUserOffers = async () => {
            const offers_data = await getOffers()
            updateOffersContaner(offers_data.data) 
        }

        const mountSentOffers = async () => {
            const data = await getSentOffers()
            updateOfferSentContainer(data.data)
        }

       
        mountSentOffers() 
        mountUserOffers()


    }, [])

    //console.log('Offer Sent: ', offerSentContainer)
    // console.log('Offers: ', offersContainer)
    // console.log('Offers Sent Status: ', offerSentStatus)
    /*
        if(offer){
            console.log('Current Offer:  ', offer)
        }
    */
   console.log('Selected Offer : ', offer)
    return (
        <Box            
             mt={{xs: -10, md: -10}}>

            
            <Stack 
                direction="column"
                spacing={{xs: 2, md: 2}}
                justifyContent="center"
                alignItems="center"
                width="100%"
                maxWidth="100%">

                    <Stack 
                        direction="column" 
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">

                        <Stack 
                            direction="row" 
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                            display="flex">

                            <Tooltip
                                placement="top-start"
                                title="Sent Offers">
                                <IconButton 
                                    sx={{ 
                                        backgroundColor: '#37363b',
                                            ":hover": {
                                            backgroundColor: '#9D9CA1'
                                        }
                                        }} 
                                    size="large"
                                    aria-label="dashboard icon"
                                    color="inherit"
                                    onClick={handleOfferSent}>
                                    <MailOutlineIcon />
                                </IconButton>
                            </Tooltip>

                            <RefreshButton text="refresh" onClick={refreshData}/>


                        </Stack>



                    </Stack>

                    <BRFPanel shadow={3}>

                    {!offersContainer.length == 0 ? (

                        <Stack 
                            mt={5}
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center">


                                
                            { offersContainer.map( ( offerElement, key) => (

                                <Grow 
                                    key={key}
                                    in={true}
                                    style={{ transformOrigin: '0 0 0'}}
                                    {...(true ? { timeout: 3000} : {} )}>
                                    <Card
                                        sx={{ 

                                            backgroundColor: '#292b38',
                                            width: { xs: '90%', sm: '90%', md: '90%', lg: "80%"}, height: '100px',
                                            
                                            }}>
                                        <CardContent>

                                            <Stack 
                                                direction="row"
                                                spacing={5}
                                                justifyContent="center"
                                                alignItems="center">

                                                    <Stack 
                                                        direction="column"
                                                        justifyContent="start" 
                                                        alignItems="start" 
                                                        spacing={2}>

                                                    

                                                        <Typography 
                                                            sx={{ color: '#ffffff', fontSize: {xs: '.6rem', md: '1rem'}}}
                                                            fontFamily='AZN'
                                                            component="div">
                                                                {`${offerElement.card_name} ${offerElement.brfcard}`}
                                                        </Typography>

                                                        

                                                    </Stack>


                                                    <Stack 
                                                        direction="column"
                                                        justifyContent="end" 
                                                        alignItems="end" 
                                                        spacing={2}>

                                                        <RegularButton 
                                                            text="view" 
                                                            onClick={() => {
                                                                handleViewOffer() 
                                                                setOffer(offerElement)
                                                            }}/>
                                                    </Stack>



                                                    

                                            </Stack>

                                        </CardContent>


                                       


                                    </Card>


                                </Grow>



                            ))}

                        </Stack>
                    ): (
                            <Stack
                                mt={10} 
                                direction="column"
                                spacing={2}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <BRFTypography
                                    text={"No Offers!"}/>

                            </Stack>
                    )}
                        

                    </BRFPanel> 

            </Stack>

            <ViewOffers 
                status={offerStatus}
                handler={handleViewOffer} 
                offer={offer}
                handleAccept={() => {handleAcceptOffer(offer.id, offer.brfcard)}}
                handleReject={() => {handleRejectOffer(offer.id)}} />

            <OffersSent 
                status={offerSentStatus} 
                handler={handleOfferSent}
                refresh={refreshData}
                data={offerSentContainer}/>



        </Box>
    )
}

export default Offers 