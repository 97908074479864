import CardMedia from '@mui/material/CardMedia'


const LogoLoader = ({ icon, width, height }) => {

    return (

        <CardMedia
            sx={{height: height, width: width, objectFit: 'contain', marginLeft: '1rem'}}
            component="img"
            src={icon}
            alt='WalletIcon'>

        </CardMedia>
                            
    )
}

export default LogoLoader