import React, { useState }from 'react'
import { Chip, Dialog, DialogContent, TextField }from '@mui/material'
import { Tooltip, Typography } from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ControlPointIcon from '@mui/icons-material/ControlPoint'



import { BRFTransition } from '../../utils/BRFTransition'


import { RegularButton } from '../BRFButtons'
import BRFTypography from '../BRFTypography'

import { pushToMarketByID } from '../../session/backend_session/Backend'



const defaultIncrementPrice = parseFloat(0.01)


const PlaceMarketPanel = ({ status, handler, data, refreshContainer }) => { 


    const [ marketPrice, setMarketPrice ] = useState(defaultIncrementPrice) 

    const [ messageStatus, setMessageStatus ] = useState(false)  
    const [ message, setMessage] = useState("")

    const [ manualEdit, setManualEdit ] = useState(false)

    const [ success, setSuccess ] = useState(false)

    const increasePrice = () => { 
        setMarketPrice( (x) => x += defaultIncrementPrice)
    }

    const increasePriceFaster = () => { 
        setMarketPrice( (x) => x += parseFloat(1))
    }

    

    const decreasePrice = () => { 
        setMarketPrice( (x) => x -= defaultIncrementPrice)
    }

    const decreasePriceFaster = () => { 
        setMarketPrice( (x) => x -= parseFloat(1))
    }

    const validateNegatives = () => { 
        if(marketPrice < 0){
            return true 
        }
        return false 
    }

    const convertToMaticFigures = () => { 
        return parseFloat(marketPrice).toFixed(2)
    }

    const handlePriceChange = ( event ) => { 

        if(event.target.value == 0 || event.target.value == NaN){
            setMarketPrice(defaultIncrementPrice)
        }

        if(event.type == 'blur' || event.key == 'Enter'){ 
            const rawPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : 0
            const tempPrice = rawPrice === 0 ? 1 : 0 
            setMarketPrice(tempPrice)
        } else if (event.type == 'change'){ 
            const newPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : null 
            setMarketPrice(newPrice)
        }

    }

    const handleManualEdit = () => { 
        setMarketPrice(defaultIncrementPrice)
        setManualEdit(!manualEdit)
    }

    const placeTokenOnMarket = async ( market_id ) => { 

        let price = convertToMaticFigures() 
        await pushToMarketByID(market_id, price)
            .then(() => { 
                setSuccess(true)
                setMessageStatus(true)
                setMessage('Successfully Placed on market!')  
            })
            .catch((error) => { 

                if(error.response.data){
                    setMessageStatus(true)
                    setMessage(error.response.data.message)
                    console.log(error)
                }
                
               console.log(error)
            })
        await refreshContainer() 
    }

   
    console.log(`Price ${convertToMaticFigures()}`)


    return ( 
        <Dialog 
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>
                
                <AppBar sx={{ position: 'relative'}}>
                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={() => { 
                                setMessage("")
                                setMessageStatus(false)
                                setSuccess(false)
                                setMarketPrice(0.0)
                                handler() 
                            }}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>

                        <BRFTypography
                            text={data.name}/>

                    </Toolbar>
                </AppBar>

                <DialogContent
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                    
                        <Stack
                            direction="column"
                            spacing={2} 
                            justifyContent="center"
                            alignItems="center" 
                            display="flex">

                            <BRFTypography
                                text="Place on Market: "
                                variant="h4"/>

                            <BRFTypography
                                text={`Token ID : ${data.tokenId}`}
                                variant="h6"/>

                            <BRFTypography
                                text={data.name}
                                variant="h6"/>

                                <Stack
                                    direction="column"
                                    spacing={2} 
                                    justifyContent="center"
                                    alignItems="center" 
                                    display="flex">
                                    
                                    {!manualEdit ? (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center" 
                                        display="flex">


                                        
                                        
                                        
                                        {!validateNegatives() ? (
                                            <Tooltip
                                                placement="top-start"
                                                title="Decrease Price">
                                                <IconButton
                                                    sx={{ 
                                                        backgroundColor: '#DE3163',
                                                            ":hover": {
                                                            backgroundColor: '#DE3163'
                                                        }
                                                        }} 
                                                    size="large"
                                                    aria-label="edit listed card"
                                                    color="inherit"
                                                    onClick={decreasePrice}
                                                    onDoubleClick={decreasePriceFaster}
                                                    >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>  
                                        ): (
                                            <Tooltip
                                                placement="top-start"
                                                title="Decrease Price">
                                                <IconButton
                                                    sx={{ 
                                                        backgroundColor: '#DE3163',
                                                            ":hover": {
                                                            backgroundColor: '#DE3163'
                                                        }
                                                        }} 
                                                    size="large"
                                                    aria-label="edit listed card"
                                                    color="inherit"
                                                    disabled 
                                                    >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>  
                                        )
                                        }  

                                        
                                        <Typography
                                            variant="h6"
                                            fontFamily="AZN"
                                            sx={{
                                                color: '#66fcf1'
                                            }}>

                                                {convertToMaticFigures()}
                                        </Typography>    
                                        

                                        

                                        <Tooltip
                                            placement="top-start"
                                            title="Increase Price">
                                            <IconButton
                                                sx={{ 
                                                    backgroundColor: '#66fcf1',
                                                        ":hover": {
                                                        backgroundColor: '#66fcf1'
                                                    }
                                                    }} 
                                                size="large"
                                                aria-label="edit listed card"
                                                color="inherit"
                                                onClick={increasePrice}
                                                onDoubleClick={increasePriceFaster}
                                                >
                                                <ControlPointIcon />
                                            </IconButton>
                                        </Tooltip>                      



                                    </Stack>
                                    ): (
                                        <TextField 
                                            sx={{
                                                background: '#fff',
                                                color: '#000', 
                                                
                                            }}
                                            variant="outlined"
                                            type="number"
                                            value={parseFloat(marketPrice)}
                                            onChange={handlePriceChange}
                                            />
                                    )}



                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center"
                                        display="flex">

                                            <Chip
                                                sx={{
                                                    color: '#66fcf1'
                                                }}
                                                variant="outlined"
                                                size="sm"
                                                label={'edit manually'}
                                                onClick={handleManualEdit}
                                            />

                                        {!success && 
                                            <RegularButton 
                                                text="Place"
                                                onClick={() => {
                                                    placeTokenOnMarket(data.market_details.market_id)
                                                }}/>
                                        }

                                        {messageStatus && 
                                            <BRFTypography 
                                                text={message}
                                                variant="h5"/>
                                        }


                                    </Stack>
                                    
                                
                                    
                                    
                                </Stack>

                            

                            

                                
                        </Stack>

                </DialogContent>
        </Dialog>
    )

}

export default PlaceMarketPanel