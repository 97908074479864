
import React, { useState }from 'react'
import { Alert, Button, Chip, CircularProgress, Dialog, DialogContent, Typography, duration }from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { BRFTransition } from '../../utils/BRFTransition'
import BRFTypography from '../BRFTypography'
import { RegularButton, DeclineButton } from '../BRFButtons'
import { Box } from '@mui/system'
import { isMobile } from '../../utils/MobileUtils'
import { signLease } from '../../session/backend_session/Backend'
import { getConnectedProvider, getWeb3Provider } from '../../session/WalletSession'
import BRFAlert from '../BRFAlert'
import { WSController, coinBaseController, metamaskController, torusController } from '../../BullApp'


const DesktopDetail = ({offer}) => {


    if(!offer){
        return (
            <Stack
                mt={5}
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                display="flex">

                    <BRFTypography 
                        text={"Nothing to see here"}/>
            </Stack>

        )
    }


    return (
        <Stack
            mt={5}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            display="flex">

                <Stack
                    direction="row" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Status: "}/>

                    {offer.accepted ? (

                        <Alert
                            variant="filled"
                            severity='success'>
                                <BRFTypography text="accepted"/>
                        </Alert>

                    ) : (
                        <Alert 
                            variant="filled"
                            severity="info">
                                <BRFTypography text="pending"/>
                            </Alert>
                    ) }
                </Stack>


                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Lease Request From: "}/>

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.card_owner}/>   

                </Stack>

                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Lease Starts Request: "}/>

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.lease_duration}/>   

                </Stack>

                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Offer Price "}/>

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.offer_price}/>   

                </Stack>


            

        </Stack>
        
    )
}


const MobileDetail = ({ offer }) => {
    return (
        <Stack
            maxWidth={'100%'}
            mt={5}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            display="flex">

                <Stack
                    direction="column" 
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Status: "}/>

                    {offer.accepted ? (

                        <Alert
                            variant="filled"
                            severity='success'>
                                <BRFTypography text="accepted"/>
                        </Alert>

                    ) : (
                        <Alert 
                            variant="filled"
                            severity="info">
                                <BRFTypography text="pending"/>
                            </Alert>
                    ) }
                </Stack>


                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Lease Request From: "}/>

                    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.offerer}/>   
                    </div>

                </Stack>

                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Lease Starts Request: "}/>

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.lease_duration}/>   

                </Stack>

                <Stack
                    direction="column" 
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                    <BRFTypography text={"Offer Price: "}/>

                    <Chip 
                        sx={{ color: '#66fcf1', fontFamily: 'Zebulon'}}
                        variant="outlined"
                        size="lg"
                        label={offer.offer_price}/>   

                </Stack>
                {offer.accepted && 

                    <Stack 
                        direction="row"
                        spacing={2}>


                        <RegularButton 
                            text={"Sign"} 
                            onClick={() => signLeaseAgreement()}/>
                        <DeclineButton text={"Abort"} colorCode={"red"} />
                    
                    </Stack>
                }

        </Stack>
                        
    )
}


const OfferSentStatus = ({ status, handler, offer, refresh, sign }) => {

    const [ alertStatus, setAlertStatus ] = useState(false)
    const [ alertIndicator, setAlertIndicator ] = useState("info")
    const [ alertMessage, setAlertMessage ] = useState("")

    const [ signing, setSigning ] = useState(false)
    const [ didLease, setDidLease ] = useState(false)

    const [ provider, setProvider ] = useState(getWeb3Provider())


    const handleAlert = () => { 
        setAlertStatus(!alertStatus)
    }

    const displayAlert = ( indicator, message ) => { 
        setAlertStatus(true)
        setAlertIndicator(indicator)
        setAlertMessage(message)
    }

    const handleLeaseSign = async (transactionHash) => { 

        /*
        * this functions handles the signing of lease to the backend and the contract

        * loading alert while step is in progress
            * step 1: call contract function to handle transfer of matic
            * step 2: get transaction if successful
            * step 3: post transaction_hash and other data(leaser, lease_duration, etc) to the backend with signLease function
            * step 4: if signLease is successful, display on alert that task is done.
            * step 5: alert user to relocate to the squad or display view(navigate to squad)
        */


        // sign the transaction by transferring amount to blockchain

        //Step 1 get provider to let them sign
        //Step 2 if transaction is successful get it (change getTransactionHash to actual function)
        //const transactionHash = await getTransactionHash(mintedTokenId)



        // post the the backend

        let data = { 
            card_id: offer.brfcard,
            offer_id: offer.id,
            lease_duration: offer.lease_duration, 
            leaser: offer.offerer, 
            lease_price: offer.offer_price, 
            receiver: offer.card_owner,
            transaction_hash: transactionHash,

        }

        await signLease(data)
            .then((res) => { 
                displayAlert('success', 'Lease signed successfully', res.data)
                displayAlert('info', 'Please relocate to the squad')

                refresh() 
            })
            .catch((error) => { 
                if(error.response){
                    displayAlert('error',error.response.data.message)
                }
            })
        
        
    }

    const durationToSeconds = (durationString) => {
        const secondsInADay = 24 * 60 * 60 // 24 hours/day * 60 minutes/hour * 60 seconds/minute
        const secondsInAMonth = 30 * 24 * 60 * 60 // Assuming 30 days in a month (approximation)
      
        switch (durationString.toLowerCase()) {
          case "a week":
            return 7 * secondsInADay
          case "1 month":
            return secondsInAMonth
          case "2 months":
            return 2 * secondsInAMonth
          case "3 months":
            return 3 * secondsInAMonth
          case "4 months":
            return 4 * secondsInAMonth
          case "5 months":
            return 5 * secondsInAMonth
          case "6 months":
            return 6 * secondsInAMonth
          default:
            throw new Error("Invalid duration string")
        }
    } 

    const convertMatic = async ( matic ) => { 
        let data =  await provider.utils.toWei(String(matic), 'ether')
        return data
    }

    const signLeaseAgreement = async () => { 
        setSigning(true)
        const expiry = durationToSeconds(offer.lease_duration)
        const offerAmount = convertMatic(offer.offer_price)
        const providerId = getConnectedProvider() 
        if(providerId == "MetaMask"){
            const agreement = await metamaskController.leaseCard(offer.card_owner, offer.offerer, expiry, offer.brfcard, offerAmount)
            setTimeout(async () => {
                try {
                  await handleLeaseSign(agreement.leaser.hash)
                  displayAlert('info', 'Lease Signed Successfully!')
                  setSigning(false)
                  setDidLease(true)
                } catch (error) {
                  displayAlert('error', 'Error Signing Lease')
                }
              }, 10000) // 10 seconds delay
            displayAlert('info', agreement.fullMessage)
            console.log("Lease Agreement", agreement)
            return 
        }

        if(providerId == "CoinBase"){
            const agreement = await coinBaseController.leaseCard(offer.card_owner, offer.offerer, expiry, offer.brfcard, offerAmount)
            setTimeout(async () => {
                try {
                  await handleLeaseSign(agreement.leaser.hash)
                  displayAlert('info', 'Lease Signed Successfully!')
                  setSigning(false)
                  setDidLease(true)
                } catch (error) {
                  displayAlert('error', 'Error Signing Lease')
                }
              }, 10000) // 10 seconds delay
            displayAlert('info', agreement.fullMessage)
            console.log("Lease Agreement", agreement)
            return 
        }

        if(providerId == "WalletConnect"){
            const agreement = await WSController.leaseCard(offer.card_owner, offer.offerer, expiry, offer.brfcard, offerAmount)
            setTimeout(async () => {
                try {
                  await handleLeaseSign(agreement.leaser.hash)
                  displayAlert('info', 'Lease Signed Successfully!')
                  setSigning(false)
                  setDidLease(true)
                } catch (error) {
                  displayAlert('error', 'Error Signing Lease')
                }
              }, 10000) // 10 seconds delay
            displayAlert('info', agreement.fullMessage)
            console.log("Lease Agreement", agreement)
            return 
        }

        if(providerId == "Torus"){
            const agreement = await torusController.leaseCard(offer.card_owner, offer.offerer, expiry, offer.brfcard, offerAmount)
            setTimeout(async () => {
                try {
                  await handleLeaseSign(agreement.leaser.hash)
                  displayAlert('info', 'Lease Signed Successfully!')
                  setSigning(false)
                  setDidLease(true)
                } catch (error) {
                  displayAlert('error', 'Error Signing Lease')
                }
              }, 10000) // 10 seconds delay
            displayAlert('info', agreement.fullMessage)
            console.log("Lease Agreement", agreement)
            return 
        }
    }



    
    return (
        <Dialog 
            fullScreen
            open={status}
            onClose={() => {
                refresh() 
                handler() 
            }}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative'}}>

                    <Toolbar>

                        <IconButton 
                            edge="start"
                            color="inherit"
                            onClick={handler}
                            aria-label="close">
                                <CloseIcon/>
                        </IconButton>


                        <BRFTypography text={offer.card_name} />

                        <Box sx={{ flex: 1}}></Box>
                        
                        {!isMobile() && offer.accepted && 

                            <Stack 
                                direction="row"
                                spacing={2}>

                            {!signing ?
                                <div>
                                {!didLease ? 
                                <RegularButton text={"Sign"} onClick={() => signLeaseAgreement()} />
                                :
                                <Button
                                    disabled={true}
                                    variant="outlined"
                                    sx={{ 
                                        width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                                        height: '3.5rem', backgroundColor: '#66fcf1',  color: '#000000', fontSize: {xs: '.7rem', sm: '.7rem', md: '.8rem', lg: '1rem'}, 
                                        fontFamily: "AZN", borderRadius: '50px',  border: 'none', opacity: '1',

                                        ":hover": { 
                                            backgroundColor: '#01dddd',
                                            border: 'none'

                                        
                                        }
                                    }}
                                    >
                                        Leaseed 
                                </Button>                                }
                                </div> 
                                : 
                                <CircularProgress
                                    color="secondary"/>
                            }
                                {!didLease && 
                                    <DeclineButton text={"Abort"} colorCode={"red"} />
                                }
                            
                            </Stack> 
                        }

                    </Toolbar>

                </AppBar>


                <DialogContent 
                    sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>

                        {!isMobile() ? (

                            <DesktopDetail offer={offer}/>
                        ): (
                            <MobileDetail offer={offer}/>
                        )}

                        

                        
                </DialogContent>

                <BRFAlert
                    status={alertStatus}
                    handler={handleAlert}
                    indicator={alertIndicator}
                    message={alertMessage}/>
        </Dialog>
    )

}

export default OfferSentStatus