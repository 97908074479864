import React, { useState } from 'react'


import { Paper, Grow, Box } from '@mui/material'

import { Stack, Button } from '@mui/material'


import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import TraitsPanel from './TraitsPanel'
import SortedByPanel from './SortedByPanel'
import ContractPanel from './ContractPanel'


const MarketActions = ({ filter_handler, sort_handler, contract_handler }) => { 


    const [ traitsPanelStatus, setTraitPanelStatus ] = useState(false) 
    const [ sortPanelStatus, setSortPanelStatus ] = useState(false) 
    const [ contractPanelStatus, setContractPanelStatus ] = useState(false) 


    const handleTraitsPanelDisplay = () => { 
        setTraitPanelStatus(!traitsPanelStatus)
    }  

    const handleSortPanelDisplay = () => { 
        setSortPanelStatus(!sortPanelStatus)
    }

    const handleContractPanelDisplay = () => {
        setContractPanelStatus(!contractPanelStatus)
    }


    return (
        <Box 
            justifyContent={"center"}
            alignItems="center"
            display="flex">

                <Grow 
                    in={true}
                    style={{ transformOrigin: '0 0 0'}}
                    {...(true ? { timeout: 1000 }: {} )}>

                    <Paper 
                        elevation={3} 
                        sx={{ 
                            backgroundColor: '#111322', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>

                            <Stack 
                                direction="row"
                                spacing={3}
                            >

                                {/* <Button 
                                    variant="contained"
                                    startIcon={<SearchIcon/>}
                                    sx={{
                                        fontSize: { xs: '.6rem', sm: '.6rem', md: '.8rem'}, 
                                        ":hover": {
                                            backgroundColor: '#2b2d55', 
                                        }
                                    }}
                                    onClick={handleTraitsPanelDisplay}
                                    
                                >
                                    Traits Filter 
                                </Button> */}

                                <Button 
                                    variant="contained"
                                    startIcon={<FilterListIcon/>}
                                    sx={{
                                        fontSize: { xs: '.6rem', sm: '.6rem', md: '.8rem'}, 
                                        ":hover": {
                                            backgroundColor: '#2b2d55', 
                                        }
                                    }}
                                    onClick={handleSortPanelDisplay}
                                >
                                    Sort By   
                                </Button>


                                <Button 
                                    variant="contained"
                                    startIcon={<AutoStoriesIcon/>}
                                    sx={{
                                        fontSize: { xs: '.6rem', sm: '.6rem', md: '.8rem'}, 
                                        ":hover": {
                                            backgroundColor: '#2b2d55', 
                                        }
                                    }}
                                    onClick={handleContractPanelDisplay}
                                >
                                    Smart Contract 
                                </Button>


                            </Stack>

                    </Paper>
                
                </Grow>

                <TraitsPanel
                    status={traitsPanelStatus}
                    handler={handleTraitsPanelDisplay}
                    filter={filter_handler}/>

                <SortedByPanel
                    status={sortPanelStatus}
                    handler={handleSortPanelDisplay}
                    sort={sort_handler}/>

                <ContractPanel
                    status={contractPanelStatus}
                    handler={handleContractPanelDisplay}
                    contract={contract_handler}/>

              
        </Box>
    )

}

export default MarketActions