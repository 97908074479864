import React, { useState } from 'react'

import { Stack, Box } from '@mui/material'
import { Card, Chip } from '@mui/material'


import BRFCardStats from './BRFCardStats'
import BRFCardDetails from './BRFCardDetails'
import { RegularButton } from '../BRFButtons'
import { isMobile } from '../../utils/MobileUtils'




const BackCard = ({handler, card_details, stat_data}) => {



    const [cardDetailsStatus, setCardDetailsStatus] = useState(false)


    const handleCardDetails = () => {
        setCardDetailsStatus(!cardDetailsStatus)
    }



    //console.log('Detail Status:', cardDetailsStatus)
    //console.log('Stat Data: ', stat_data)
    //console.log('Card Details: ', card_details)
     return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center">

            <Card
                sx={{ 

                    height: isMobile() ? '401px' : '100%',
                    width: { xs: '307px', md: '264px' },
                    cursor: 'pointer',
                    backgroundColor: '#111322',
                    justifyContent: 'center',
                    margin: '1px',
                    paddingBottom: 1,
                    display: 'flex',
                    '&:hover': {

                    }
                }}
                onClick={handler}>
                
                <Stack
                    mt={2} 
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">

                        <Chip
                            sx={{
                                color: '#fff', 
                                fontWeight: 'bolder',
                            }}
                            variant="outlined"
                            fontFamily="DiloWorld"
                            size="lg"
                            label={card_details.name}
                            />

                            
                        {stat_data && 
                            <BRFCardStats
                                strength={stat_data.strength_stat}
                                speed={stat_data.speed_stat}
                                endurance={stat_data.endurance_stat}
                                dexterity={stat_data.dexterity_stat}
                                leadership={stat_data.leadership_stat}
                                intelligence={stat_data.intelligence_stat}
                            />   
                        }

                        <Box>
                         <RegularButton text="more" onClick={handleCardDetails}/>
                        </Box>

                </Stack>
                <BRFCardDetails status={cardDetailsStatus} handler={handleCardDetails} details={card_details}/>

            </Card>


        </Box>

     )
}

export default BackCard