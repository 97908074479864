import React from 'react'

import { CardMedia } from '@mui/material'
import { isMobile } from '../../utils/MobileUtils'

const BRFCardMedia = ({ipfs_uri}) => {

    return (

        <CardMedia
            sx={{
                objectFit: 'cover', width: '100%', height: '100%', paddingRight: ''
            }}
            component="img"
            src={ipfs_uri}/>

    )
    

  
 }

export default BRFCardMedia