import React from 'react'
import { Paper } from '@mui/material'


const LicenseContainer = ({shadow, children}) => {
    return (
        <Paper 
            elevation={shadow} 
            sx={{ 
                    backgroundColor: '#fff', width: { xs: '350px', sm: '70vh', md: '100vh', lg: '100vh'}, color: '#000',
                    height:  {xs: '75vh', sm: '75vh', md: ' 75vh'}, maxHeight: {xs: '75vh', md:'75vh'}, borderRadius: '10px', overflow: 'auto',
                  
                }}>

                    {children}
                    
        </Paper>
    )
}


export {
    LicenseContainer, 
}