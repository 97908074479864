import React from 'react' 

import { Box, Stack, Paper } from '@mui/material'
import { Typography } from '@mui/material'

import ComingSoon from '../../components/ComingSoon'

import BRFTypography from '../../components/BRFTypography'

import { isMobile } from '../../utils/MobileUtils'
const Play = () => {
    
    if(isMobile){
        return (
            <Box 

                mt={-7}
                >
                <Stack 
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center">

                    <Typography 
                        sx={{ color: '#ffffff'}}
                        variant="p"
                        fontFamily="AZN"
                        noWrap
                        component="div">
                            Simulation Game Coming Soon
                    </Typography>                        

                        <Paper 
                            elevation={10}
                            sx={{ 
                                backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '150vh'}, maxWidth: '150vh',
                                height:  {xs: '100%', sm: '100%', md: ' auto'}, maxHeight: '75vh', borderRadius: '10px', overflow: {xs: 'hidden', sm: 'hidden', md: 'hidden'},
                            }}>

                            <Stack 
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                alignItems="center">

                                <ComingSoon/>


                            </Stack>

                        </Paper>

                        


                    
                </Stack>
            </Box>
        )
    }
    return (
        
        <Box mt={-7}>
            
           <Stack 
                direction="column"
                spacing={5}
                justifyContent="center"
                alignItems="center">

               <BRFTypography variant="h4" text="Simulation Game Coming Soon!"/>
                
                
                <Paper 
                    elevation={10}
                    sx={{ 
                        backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '100vh'},
                        height:  {xs: '650px', sm: '650px', md: ' 70vh'}, maxHeight: '70vh', borderRadius: '10px', overflow: 'auto'
                    }}>

                    <Stack 
                        direction="column"
                        spacing={0}
                        justifyContent="center"
                        alignItems="center">

                        <ComingSoon />


                    </Stack>

                </Paper>

                


               
            </Stack>

        </Box>
   )
}

export default Play 