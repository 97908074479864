import React from 'react';
import { Stack } from '@mui/material';
import { Chip, Divider } from '@mui/material';

const StatChip = ({ label, value }) => (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Chip
        sx={{
          fontWeight: 'bolder',
          color: '#fff',
          width: "75px"
        }}
        variant="outlined"
        fontFamily="Zelot-Light"
        size="sm"
        label={label}
      />
      <Chip
        sx={{
          fontWeight: 'bolder',
          width: '75px', // Set a fixed width for the value Chip
        }}
        variant="outlined"
        size="sm"
        color="success"
        fontFamily="Zelot-Light"
        label={value || '0.00'} // Display "0" for empty values
      />
    </Stack>
  );
  

  const BRFCardStats = ({ strength, speed, endurance, dexterity, leadership, intelligence }) => {
    return (
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
        <StatChip label="STR" value={strength} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
        <StatChip label="SPD" value={speed} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
        <StatChip label="END" value={endurance} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
        <StatChip label="DEX" value={dexterity} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
        <StatChip label="LDR" value={leadership} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
        <StatChip label="INT" value={intelligence} />
        <Divider sx={{ backgroundColor: '#66fcf1', width: '100%' }} />
      </Stack>
    );
  };
  

export default BRFCardStats;
