import React, {useState} from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import { Link } from "react-router-dom"


import MenuIcon from '@mui/icons-material/Menu'
import InsightsIcon from '@mui/icons-material/Insights'
import GroupsIcon from '@mui/icons-material/Groups'
import PaidIcon from '@mui/icons-material/Paid'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'


import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({

  palette: {
    primary: {
      main: '#66fcf1', 
      dark: '#66fcf1', 
      contrastText: '#000'
    },
  }
})


const DashboardNavigation = ({ handleWalletDisconnect }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  

  return (
    <Box  sx={{ position: 'fixed', bottom: 18, right: 20}}>

    <ThemeProvider theme={theme}>
        <SpeedDial
          ariaLabel="SpeedDial controlled open example"
          icon={<MenuIcon/>}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}>

              <SpeedDialAction sx={{ backgroundColor: '#DE3163', ':hover': { backgroundColor: '#DE3163'}}}
                  icon={<LogoutIcon/>}
                  tooltipTitle={"disconnect"}
                  onClick={handleWalletDisconnect}/>

              <SpeedDialAction component={Link} to={"/overview"}
                  icon={<InsightsIcon/>}
                  tooltipTitle={"Overview"}
                  onClick={handleClose}/>

              <SpeedDialAction component={Link} to={"/myteam"}
                  icon={<GroupsIcon/>}
                  tooltipTitle={"My Team"}
                  onClick={handleClose}/>

              <SpeedDialAction component={Link} to={"/transactions"}
                  icon={<PaidIcon/>}
                  tooltipTitle={"Transactions"}
                  onClick={handleClose}/>

              {/*<SpeedDialAction component={Link} to={"/sales"}
                  icon={<SellIcon/>}
                  tooltipTitle={"Sales"}
                  onClick={handleClose}/> */}

              <SpeedDialAction component={Link} to={"/offers"}
                  icon={<AttachMoneyIcon/>}
                  tooltipTitle={"Offers"}
                  onClick={handleClose}/>

              

              <SpeedDialAction component={Link} to={"/settings"}
                  icon={<SettingsIcon/>}
                  tooltipTitle={"settings"}
                  onClick={handleClose}/>

          </SpeedDial>
      </ThemeProvider>

            

    </Box>
  )
}

export default DashboardNavigation
