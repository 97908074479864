import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Faq from './FAQ.js';

import CloseIcon from '@mui/icons-material/Close'

export default function FaqDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar sx={{ position: 'sticky', backgroundColor: 'rgba(0,0,0,0.9)', boxShadow: 0 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" fontFamily='Roboto Slab' sx={{ flexGrow: 1, textAlign: 'center' }}>
            FAQ
          </Typography>
        </Toolbar>
      </AppBar>
      <Faq />
    </Dialog>
  );
}
