import React from 'react' 

import { Dialog, AppBar, Toolbar, IconButton, DialogContent, Typography } from '@mui/material'
import { Stack } from '@mui/material' 

import CloseIcon from '@mui/icons-material/Close'
 
import { BRFTransition } from '../../utils/BRFTransition'

import { PriceAdjuster } from '../Adjuster'



const AdjustPrice = ({ status, handler, market_id, tokenId, refresh}) => { 

    
   

    return ( 
        <Dialog 
            open={status}
            TransitionComponent={BRFTransition}
            keepMounted
            onClose={handler}
            fullWidth>

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close"
                    
                    >
                    <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                       Adjust Price  
                    </Typography>

                </Toolbar>
            </AppBar>



            <DialogContent

                display="flex"
                sx={{ backgroundColor: '#111321', maxWidth: '100%'}}>

                <Stack
                    direction="column"
                    spacing={2} 
                    justifyContent="center"
                    alignItems="center" 
                    display="flex">
                   
                   <PriceAdjuster
                        market_id={market_id}
                        tokenId={tokenId}
                        refresh={refresh}/>

                   
                </Stack>

            </DialogContent>

        </Dialog>
    )


}

export default AdjustPrice