import  React, { useContext }  from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import { AppBar, Toolbar } from '@mui/material'

import { Typography, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'


import { BRFTransition } from '../utils/BRFTransition'


import WalletIcon from './WalletIcon'
import walletconnectIcon from '../logos/walletconnect.png' 
import torusIcon from '../logos/torusIcon.png'

import { WSController, torusController } from '../BullApp'

const BRFMobileWallet = ({ status, handler }) => {
    

    return (
      
      <Dialog
        open={status}
        TransitionComponent={BRFTransition}
        keepMounted
        onClose={handler}
        fullWidth 
        aria-describedby="wallet connect handler">

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handler}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Light">
                        Choose Wallet
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box sx={{ backgroundColor: '#111321'}}>

                <Stack 
                    direction="column"
                    spacing={5}
                    justifyContent="center"
                    alignItems="center">

                        
                        <Stack 
                            direction={{ xs: 'column', md: 'row'}}
                            spacing={{xs: 3, md: 10}}
                            justifyContent="center"
                            alignItems="center">

                                <IconButton
                                    size="large"
                                    aria-label="remove"
                                    variant="outlined"
                                    sx={{ color: 'white'}}
                                    onClick={() => {
                                        handler()
                                        WSController.handleConnect()
                                    }}>
                                            <WalletIcon icon={walletconnectIcon}/>
                                </IconButton>

                                <IconButton 
                                    size="large"
                                   aria-label="remove" 
                                    variant="outlined" 
                                    sx={{ color: 'white' }} 
                                    onClick={() => torusController.handleConnect()}>
                                            <WalletIcon icon={torusIcon} />
                                </IconButton>

                        </Stack>


                </Stack>

            </Box>


      </Dialog>
  )
}


export default BRFMobileWallet