import React, { useEffect, useState  } from 'react' 

import {Box, Stack , Paper, Grow} from '@mui/material'
import {Card, CardContent} from '@mui/material'
import { Typography } from '@mui/material'

import BRFTypography from '../../components/BRFTypography'
import ComingSoon from '../../components/ComingSoon'

import { isMobile } from '../../utils/MobileUtils'
import { RegularButton } from '../../components/BRFButtons'
import { getRecentOffers, acceptOffer, rejectOffer, getRecentTransactions } from '../../session/backend_session/Backend'
import ViewOffers from '../../components/ViewOffers'
import ViewTransactions from '../../components/ViewTransactions'

const Overview = () => {

    const [offer, setOffer] = useState({})
    const [currentTransaction, setCurrentTransaction] = useState({})
    const [recentOffers, setRecentOffers] = useState([])
    const [recentTransactions, setRecentTransactions] = useState([])

    const [offerStatus, setOfferStatus] = useState(false) 
    const [transactionStatus, setTransactionStatus] = useState(false)
    

    const handleViewOffer = () => {
        setOfferStatus(!offerStatus)
    }

    const handleTransactionView = () => { 
        setTransactionStatus(!transactionStatus)
    }


    const handleAcceptOffer = async ( id ) => {
        await acceptOffer(id)
        refreshData() 
    }

    const handleRejectOffer = async ( id ) => {
        await rejectOffer(id)
        refreshData()
        handleViewOffer() 
    }

    const refreshData = async () => {
        const offers_data = await getRecentOffers() 
        setRecentOffers(offers_data.data) 
    }

    useEffect(() => {

        const mountRecentOffers = async () => {

            const data = await getRecentOffers()
            setRecentOffers(data.data)

        }

        const mountRecentTransactions = async () => { 

            const transactions = [] 
            const transactions_data = await getRecentTransactions()
            if(transactions_data.data){

                const sender = transactions_data.data.sender
                const receiver = transactions_data.data.receiver 

                sender.map((data) => { 
                    transactions.push(data)
                })

                receiver.map((data) => { 
                    transactions.push(data)
                })
                
            }

            setRecentTransactions(transactions)

        }

        mountRecentOffers() 
        mountRecentTransactions() 

    }, [])

    if(isMobile()){
        return (
            <Box mt={-7}>
            
            <Stack 
                direction="column"
                spacing={5}
                justifyContent="center"
                alignItems="center">
                    
                <Stack 
                    direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                    spacing={10}
                    justifyContent="center"
                    alignItems="center">

                    
                        
                    <Stack 
                        direction="column"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center" 
                        padding={1}>

                        <BRFTypography variant="h4" text="Overview"/>

                        <Grow
                            in={true}
                            style={{ transformOrigin: '0 0 0'}}
                            {...(true ? { timeout: 1000} : {} )}>
                            <Paper 
                                elevation={10}
                                sx={{ 
                                    backgroundColor: '#292b38', width: { xs: '100%', sm: '100%', md: '100vw', lg: '100vh'},
                                    height: { xs: 'auto', md: '72vh', lg: '88vh' }, maxHeight: {xs: '50vh', sm: '60vh', md: '75vh'},
                                    borderRadius: '10px', overflow: 'auto' 
                                }}>

                                    <Stack 
                                        direction="column"
                                        spacing={5}
                                        justifyContent="center"
                                        alignItems="center">

                                            <ComingSoon objectFit="cover" />

                                                
                                    </Stack>

                            </Paper>

                        </Grow>

                    </Stack>


                   


                </Stack>


            </Stack>
        </Box>
        )
    }
    return (
        <Box mt={-7}>
            
            <Stack 
                direction="column"
                spacing={5}
                justifyContent="center"
                alignItems="center">
                    
                <Stack 
                    direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                    spacing={10}
                    justifyContent="center"
                    alignItems="center">

                    
                        
                    <Stack 
                        direction="column"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center">

                        <Typography
                            mt={5}
                            sx={{ color: '#ffffff'}}
                            variant="h6"
                            component="div"
                            fontFamily="AZN">
                                Game Overview 
                        </Typography> 

                        <Grow 
                            in={true}
                            style={{ transformOrigin: '0 0 0'}}
                            {...(true ? { timeout: 1000} : {} )}>

                            <Paper 
                                elevation={10}
                                sx={{ 
                                    backgroundColor: {xs: '#111322', md:'#292b38'}, width: { xs: '350px', sm: '100%', md: '70vh', lg: '100vh'},
                                    height: {xs: '100%', sm: '100%', md: 'auto'}, maxHeight: '70vh', borderRadius: '10px', overflow: 'hidden',
                                }}>

                                    <Stack 
                                        direction="column"
                                        spacing={0}
                                        justifyContent="center"
                                        alignItems="center">

                                        <ComingSoon objectFit="cover"/>
                                                
                                    </Stack>

                            </Paper>

                        </Grow>

                    </Stack>


                    <Stack 
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center">

                        

                        <Stack 
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center">

                            <Typography
                                mt={5}
                                sx={{ color: '#ffffff'}}
                                variant="h6"
                                component="div"
                                fontFamily="AZN">
                                    Recent Offers 
                            </Typography>        


                            <Grow 
                                in={true}
                                style={{ transformOrigin: '0 0 0'}}
                                {...(true ? { timeout: 2000} : {} )}>
                                <Paper 
                                    elevation={10}
                                    sx={{ 
                                        backgroundColor: '#292b38', width: { xs: '350px', sm: '100%', md: '40vh', lg: '50vh'},
                                        height: '30vh', maxHeight: '30vh', borderRadius: '10px', overflow: 'auto'
                                    }}>

                                    
                                    {!recentOffers.length == 0 ? (

                                    <Stack
                                        mt={5} 
                                        direction="column"
                                        spacing={5}
                                        justifyContent="center"
                                        alignItems="center">

                                            

                                            {/* Change later */}
                                            {recentOffers.map( (offer, key) => (

                                                <Card
                                                    key={key}
                                                    sx={{ 

                                                        backgroundColor: '#292b38',
                                                        width: { xs: '90%', sm: '90%', md: '90%', lg: "80%"}, height: '100px',
                                                        
                                                        }}>
                                                    <CardContent>

                                                        <Stack 
                                                            direction="row"
                                                            spacing={5}
                                                            justifyContent="center"
                                                            alignItems="center">

                                                                <Stack 
                                                                    direction="column"
                                                                    justifyContent="start" 
                                                                    alignItems="start" 
                                                                    spacing={2}>

                                                                

                                                                    <Typography 
                                                                        sx={{ color: '#ffffff'}}
                                                                        fontFamily="AZN"
                                                                        variant="p"
                                                                        component="div">
                                                                            {offer.card_name}
                                                                    </Typography>

                                                                    

                                                                </Stack>


                                                                <Stack 
                                                                    direction="column"
                                                                    justifyContent="end" 
                                                                    alignItems="end" 
                                                                    spacing={2}>

                                                                    <RegularButton 
                                                                        text="view" 
                                                                        onClick={() => {
                                                                            handleViewOffer()
                                                                            setOffer(offer)
                                                                        }}/>

                                                                </Stack>



                                                                

                                                        </Stack>

                                                    </CardContent>

                                                    

                                                </Card>

                                            ))}


                                    </Stack>
                                    ): (
                                        <Stack
                                            mt={10} 
                                            direction="column"
                                            spacing={2}
                                            justifyContent="center"
                                            alignItems="center"
                                            display="flex">

                                            <BRFTypography
                                                text={"No Offers!"}/>

                                        </Stack>
                                    )}

                                </Paper>
                            </Grow>
                        </Stack>




                        <Stack 
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center">

                            <Typography
                                mt={5}
                                sx={{ color: '#ffffff'}}
                                variant="h6"
                                component="div"
                                fontFamily="AZN">
                                    Recent Transactions 
                            </Typography>       

                            <Grow 
                                in={true}
                                style={{ transformOrigin: '0 0 0'}}
                                {...(true ? { timeout: 3000} : {} )}>
                                <Paper 
                                    elevation={10}
                                    sx={{ 
                                        backgroundColor: '#292b38', width: { xs: '350px', sm: '100%', md: '40vh', lg: '50vh'},
                                        height: '30vh', maxHeight: '30vh', borderRadius: '10px', overflow: 'auto'
                                    }}>

                                    

                                        
                                        {!recentTransactions.length == 0 ? (
                                            <Stack 
                                                mt={5} 
                                                direction="column"
                                                spacing={5}
                                                justifyContent="center"
                                                alignItems="center">


                                                {recentTransactions.map( (transaction, key) => (

                                                <Card
                                                    key={key}
                                                    sx={{ 

                                                        backgroundColor: '#292b38',
                                                        width: { xs: '90%', sm: '90%', md: '90%', lg: "80%"}, height: '100px',
                                                        
                                                        }}>
                                                    <CardContent>

                                                        <Stack 
                                                            direction="row"
                                                            spacing={5}
                                                            justifyContent="center"
                                                            alignItems="center">

                                                                <Stack 
                                                                    direction="column"
                                                                    justifyContent="start" 
                                                                    alignItems="start" 
                                                                    spacing={2}>

                                                                

                                                                    <Typography 
                                                                        sx={{ color: '#ffffff'}}
                                                                        fontFamily="AZN"
                                                                        variant="p"
                                                                        component="div">
                                                                            {`Transaction ID: ${transaction.id}`}
                                                                    </Typography>

                                                                    

                                                                </Stack>


                                                                <Stack 
                                                                    direction="column"
                                                                    justifyContent="end" 
                                                                    alignItems="end" 
                                                                    spacing={2}>

                                                                    <RegularButton 
                                                                        text="view"
                                                                        onClick={() => { 
                                                                            handleTransactionView()
                                                                            setCurrentTransaction(transaction)
                                                                        }}/>

                                                                </Stack>



                                                                

                                                        </Stack>

                                                    </CardContent>
                                                </Card>

                                                ))}
                                            </Stack>
                                               
                                            ): (
                                        
                                                <Stack
                                                    mt={10} 
                                                    direction="column"
                                                    spacing={2}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    display="flex">

                                                    <BRFTypography
                                                        text={"No Transactions!"}/>

                                                </Stack>
                                            )}

                                                    

                                </Paper>
                            </Grow> 
                        </Stack>




                    </Stack>


                </Stack>
            </Stack>


            <ViewOffers 
                status={offerStatus}
                handler={handleViewOffer} 
                offer={offer}
                handleAccept={() => {handleAcceptOffer(offer.id)}}
                handleReject={() => {handleRejectOffer(offer.id)}} />


            <ViewTransactions
                status={transactionStatus}
                handler={handleTransactionView}
                transaction={currentTransaction}/>

        </Box>
    )
}

export default Overview 