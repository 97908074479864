

import { Web3Provider } from "@ethersproject/providers"


const getLibrary = ( provider ) => {
    return new Web3Provider(provider)
}


const mainnet = 137
const testnet =  80001
const detectChainID = ( chainID ) => {

    if(chainID == mainnet){
        return 'Polygon Mainnet'
    }

    if(chainID == testnet){
        return 'Matic Mumbai'
    }

    return 'Unsupported Chain'
}


export { 

    getLibrary,
    detectChainID,
}

