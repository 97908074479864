import React from 'react' 

import {Box, Stack, Paper, Chip} from '@mui/material' 
import { Typography } from '@mui/material'
import BRFTypography from '../../../components/BRFTypography'
import { getConnectedChainID, getConnectedProvider, getConnectedWallet } from '../../../session/WalletSession'


import { isMobile } from '../../../utils/MobileUtils'
import { detectChainID } from '../../../utils/BRFSessionUtils'

const Profile = () => {


    const renderChain = () => {
        let chainID = getConnectedChainID()
        return detectChainID(chainID) 
    }

    const renderMobileProfile = () => {

        return (
            <Box mt={-5}>

            <Stack 
                direction="column"
                spacing={3}
                justifyContent="center"
                alignItems="center">
               
                <BRFTypography
                    text="Profile"/>


                <Paper 
                    elevation={3} 
                    sx={{ 
                            backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '100vh'},
                            height: '500px', maxHeight: '500px', borderRadius: '10px', overflow: 'auto'
                        }}>

                            <Stack 
                                mt={5} 
                                direction="column"
                                spacing={2}
                                justifyContent="center"
                                alignItems="center">

                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center">

                                            <BRFTypography
                                                variant="h5"
                                                text="Connected Wallet: "/>

                                            <Chip 
                                                sx={{ color: '#66fcf1', width: '70%'}}
                                                variant="outlined"
                                                fontFamily='AZN'
                                                label={getConnectedWallet()}/>
                                    </Stack>

                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center">

                                            <BRFTypography
                                                variant="h5"
                                                text="Wallet Provider: "/>

                                            <Chip 
                                                sx={{ color: '#66fcf1', width: '100%'}}
                                                variant="outlined"
                                                fontFamily='AZN'
                                                label={getConnectedProvider()}/>
                                    </Stack>


                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center">

                                            <BRFTypography
                                                variant="h5"
                                                text="Connected Chain: "/>

                                            <Chip 
                                                sx={{ color: '#66fcf1', width: '100%'}}
                                                variant="outlined"
                                                fontFamily='AZN'
                                                label={renderChain()}/>
                                    </Stack>
                            </Stack>


                            
                </Paper>


            </Stack>

            </Box>
        )
    }

    
    if(isMobile()){
        return (
            renderMobileProfile()
        )
    }


    return (
        <Box mt={-5}>

        <Stack 
            direction="column"
            spacing={3}
            justifyContent="center"
            alignItems="center">

            <BRFTypography variant="h4" text="Profile"/>



            <Paper 
                elevation={3} 
                sx={{ 
                        backgroundColor: '#111322', width: { xs: '350px', sm: '100%', md: '70vh', lg: '100vh'},
                        height:  {xs: '650px', sm: '650px', md: ' 70vh'}, maxHeight: '70vh', borderRadius: '10px', overflow: 'auto'
                    }}>

                        <Stack 
                            mt={5} 
                            direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                            spacing={{xs: 2, sm: 2, md: 8, lg: 10}}
                            justifyContent="center"
                            alignItems="center">

                                <Stack 
                                    direction="column"
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems="center">

                                        <BRFTypography
                                            variant="h5"
                                            text="Connected Wallet: "/>

                                        <BRFTypography
                                            variant="h5"
                                            text="Wallet Provider: "/>

                                        <BRFTypography
                                            variant="h5"
                                            text="Connected Chain"/>
                                </Stack>

                                <Stack 
                                    direction="column"
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems="center">

                                        <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '10rem'}}> 
                                            <Chip 
                                                sx={{ color: '#66fcf1'}}
                                                variant="outlined"
                                                fontFamily='AZN'
                                                label={getConnectedWallet()}/>
                                        </div>

                                        <Chip 
                                            sx={{ color: '#66fcf1'}}
                                            variant="outlined"
                                            fontFamily='AZN'
                                            label={getConnectedProvider()}/>

                                        <Chip 
                                            sx={{ color: '#66fcf1'}}
                                            variant="outlined"
                                            fontFamily='AZN'
                                            label={renderChain()}/>
                                </Stack>
                        </Stack>


                        
            </Paper>


        </Stack>

    </Box>
    )
}

export default Profile 