import React from 'react' 

import { Box, Stack } from '@mui/material'

import BRFPanel from '../../../components/BRFPanel'
import BRFTypography from '../../../components/BRFTypography'
import ComingSoon from '../../../components/ComingSoon'


const Settings = () => {
    return (

        <Box 
        mt={-10}

        display="flex"
        justifyContent="center"
        alignItems="center"
        >

           <Stack 
                direction="column"
                spacing={5}
                justifyContent="center"
                alignItems="center">

                
                    <BRFTypography variant="h6" text="Settings"/>

                    <BRFPanel>

                        <Stack 
                            mt={5}
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center">
                                <ComingSoon />
                        

                        </Stack>
                        
                    </BRFPanel>
            </Stack>

        </Box>
    )
}

export default Settings 