import React, { useState } from 'react'



import { Card, Dialog, DialogContent, Stack, TextField } from '@mui/material' 
import { Toolbar, AppBar, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import { BRFTransition } from '../utils/BRFTransition'
import BRFTypography from './BRFTypography'
import { RegularButton } from './BRFButtons'
import BRFPanel from './BRFPanel'
import ResultView from './ResultView'

import { Search, SearchIconWrapper, StyledInputBase } from '../utils/BRFNavUtils'
import BRFAlert from './BRFAlert'
import { searchPlayers } from '../session/backend_session/Backend'


const defaultCardData = {
    name: 'Error'
}

const MobileSearchDisplay = ({ status, handler}) => {

    const [searchList, setSearchList] = useState([])
    const [card_data, updateCardData] = useState(defaultCardData)
    const [resultViewStatus, setResultViewStatus] = useState(false) 


    const [alertStatus, setAlertStatus] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertIndicator, setAlertIndicator] = useState(null)


    const [searchInput, updateSearchInput] = useState("")


    const displayAlert = (indicator, message) => {
        setAlertStatus(true)
        setAlertMessage(message)
        setAlertIndicator(indicator)
    }

    const handleAlert = () => {
        setAlertStatus(!alertStatus)
    }
    const viewResultedCard = (data) => {
        updateCardData(data)
        setResultViewStatus(true) 
    }

    const resultViewHandler = () => {
        setResultViewStatus(false)
    }

    const handleSearchInput = (event) => {
        updateSearchInput(event.target.value)
    }

    const handleSearch = async (event) => {
        
        if(event.keyCode == 13){
            if(searchInput == ''){
                displayAlert('info', 'Type Something')
                return 
            }
            
            displayAlert('info', 'Searching......')
            return await searchPlayers(searchInput)
                    .then((res) => {
                        displayAlert('success', 'Loading Results')
                        setSearchList(res.data) 
                    })
                    .catch(() => {
                        displayAlert('error', 'Something Went Wrong!')
                    })
        }
    }

    return (

        <Dialog
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>

                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">

                        <CloseIcon />

                        </IconButton>


                        <Search 
                            >

                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>

                            <StyledInputBase
                                placeholder="Lookup Player ID, Owner Address, Team Name"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={handleSearchInput}
                                value={searchInput}
                                onKeyDown={handleSearch}
                                
                                
                                />

                        </Search>



                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ backgroundColor: '#111322' }}>

                    <Stack 
                        mt={5}
                        direction="column"
                        spacing={10}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">

                            <BRFTypography text="Closest Match Found: "/>

                            <BRFPanel shadow={0}>

                                    {searchList.map( (value, key) => (
                                        <Card
                                            key={key}
                                            sx={{
                                                height: '20vh',
                                                width: '100%',
                                                backgroundColor: '#292b38',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                marginTop: '1rem'
                                            }}
                                            >

                                        <Stack 
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center">

                                                    <BRFTypography text={value.name}/>
                                                    <RegularButton text={"view"} onClick={() => {viewResultedCard(value)}}/>

                                            </Stack>
                                            
                                        </Card>

                                    ))}
                            </BRFPanel>
                    </Stack>

                </DialogContent>
                
                <ResultView status={resultViewStatus} handler={resultViewHandler} data={card_data}/>
                <BRFAlert
                    status={alertStatus}
                    handler={handleAlert}
                    indicator={alertIndicator}
                    message={alertMessage}/>

        </Dialog>
)
}

export default MobileSearchDisplay