import React from 'react'
import ReactDOM from 'react-dom/client'
import 'stream-browserify';


import BullApp from './BullApp'

import './theme/dark.css'
import './theme/bullcard.css'

import { createTheme, ThemeProvider } from '@mui/material'


const theme = createTheme({

  palette: {
    primary: {
      main: '#0f111f', 
      dark: '#0f111f', 
    },
  }
})




const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <BullApp />
    </ThemeProvider>
  </React.StrictMode>
)

