import React, { useState } from 'react' 


import { Card, Dialog, DialogContent, Stack } from '@mui/material' 
import { Typography, Toolbar, AppBar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { BRFTransition } from '../../utils/BRFTransition'
import BRFTypography from '../BRFTypography'
import { RegularButton } from '../BRFButtons'
import BRFPanel from '../BRFPanel'
import OfferSentStatus from './OfferSentStatus'


const defaultCardData = {
    name: 'Error'
}


const OffersSent = ({ status, handler, refresh, data}) => {

    const [card_data, updateCardData] = useState(defaultCardData) 
    const [offerDetailStatus, setOfferDetailStatus] = useState(false) 

    const handleOfferDetail = () => {
        setOfferDetailStatus(!offerDetailStatus)
    }

    const viewResultedCard = (data) => {
        updateCardData(data)
        setOfferDetailStatus(true) 
        refresh() 
    }



    return (
        <Dialog
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>

                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">

                    <CloseIcon />

                    </IconButton>


                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="Zelot-Light">
                        {data.name} 
                    </Typography>




                </Toolbar>
            </AppBar>

            <DialogContent sx={{ backgroundColor: '#111322', maxWidth: '100%'}}>
            <Stack 
                    mt={10}
                    direction="column"
                    spacing={10}
                    justifyContent="center"
                    alignItems="center"
                    display="flex">

                        <BRFTypography text="Request Offers "/>

                        <BRFPanel shadow={0}>

                                {data.map( (offer, key) => (
                                    <Card
                                        key={key}
                                        sx={{
                                            height: '20vh',
                                            width: { xs: '100%', md: '100vh'},
                                            backgroundColor: '#292b38',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            marginTop: '1rem'
                                        }}
                                        >

                                        <Stack 
                                            direction="column"
                                            spacing={2}
                                            justifyContent="center"
                                            alignItems="center">

                                                <BRFTypography text={offer.card_name}/>


                                                <RegularButton 
                                                    text={"view"}
                                                    onClick={() => { 
                                                        viewResultedCard(offer)
                                                        }}/>

                                        </Stack>

                                        
                                    </Card>

                                ))}
                        </BRFPanel>
                </Stack>


            </DialogContent>
            <OfferSentStatus 
                status={offerDetailStatus} 
                handler={handleOfferDetail} 
                refresh={refresh}
                offer={card_data}/>



        </Dialog>

    )
}

export default OffersSent