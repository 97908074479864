import React, {useState} from 'react' 


import { Card, Dialog, DialogContent, Stack } from '@mui/material' 
import { Typography, Toolbar, AppBar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { BRFTransition } from '../utils/BRFTransition'
import BRFTypography from './BRFTypography'
import { RegularButton } from './BRFButtons'
import BRFPanel from './BRFPanel'
import ResultView from './ResultView'

const defaultCardData = {
    name: 'Error'
}

const SearchDisplay = ({status, handler, searchList}) => {

    const [card_data, updateCardData] = useState(defaultCardData) 
    const [resultCount, updateResultCount] = useState(searchList.length)
    const [resultViewStatus, setResultViewStatus] = useState(false) 

    const viewResultedCard = (data) => {
        updateCardData(data)
        setResultViewStatus(true) 
    }

    const resultViewHandler = () => {
        setResultViewStatus(false)
    }

    //console.log('Card Data: ',card_data)

    return (
        <Dialog
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>

                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">

                        <CloseIcon />

                        </IconButton>


                        <Stack sx={{ ml: 2, flex: 1 }}
                            direction="row"
                            spacing={5}
                        >
                            <Typography  variant="h6" component="div" fontFamily="Zelot-Light">
                                Result Found 
                            </Typography>

                        {/*
                            <Chip 
                                sx={{ color: '#66fcf1'}}
                                variant="outlined"
                                size="lg"
                                label={resultCount}/>
                        */}

                        </Stack>




                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ backgroundColor: '#111322' }}>

                    <Stack 
                        mt={5}
                        direction="column"
                        spacing={10}
                        justifyContent="center"
                        alignItems="center"
                        display="flex">

                            <BRFTypography text="Closest Match Found: "/>

                            <BRFPanel shadow={0}>

                                    {searchList.map( (value, key) => (
                                        <Card
                                            key={key}
                                            sx={{
                                                height: '20vh',
                                                width: {xs: '100%', md: '100vh'},
                                                backgroundColor: '#292b38',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                marginTop: '1rem'
                                            }}
                                            >

                                           <Stack 
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center">

                                                    <BRFTypography text={value.name}/>
                                                    <RegularButton text={"view"} onClick={() => {viewResultedCard(value)}}/>

                                            </Stack>
                                            
                                        </Card>

                                    ))}
                            </BRFPanel>
                    </Stack>

                </DialogContent>
                
                <ResultView status={resultViewStatus} handler={resultViewHandler} data={card_data}/>

        </Dialog>
    )
}

export default SearchDisplay