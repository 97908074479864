import React, { useState }from 'react'
import { Dialog, DialogContent, FormControl, InputLabel }from '@mui/material'


import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'


import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack' 
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import { BRFTransition } from '../../utils/BRFTransition'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { RegularButton } from '../BRFButtons'


const theme = createTheme({

    palette: {
      primary: {
        main: '#66fcf1', 
        dark: '#66fcf1', 
        contrastText: '#fff',
        background: '#fff'
      },
  
    }, 
   
})

const sortInfo = ['recently_listed', 'lowhigh', 'highlow']
const sortDisplay = [ 'Recently Listed', 'Low - High', 'High - Low']


const SortedByPanel = ({ status, handler, sort}) => { 


    const [sortedValue, updateSortedValue] = useState("")

    const handleSortedValue = (event) => { 

        let value = event.target.value 
        if(sortInfo.includes(value)){
            updateSortedValue(value)
        }
    }

    const translateSortInfoArr = ( value ) => { 
        if(value == sortInfo[0]){
            return 'Recently Listed'
        }
        if(value == sortInfo[1]){
            return 'Low - High'
        }
        if(value == sortInfo[2]){
            return 'High - Low'
        }
    }

    console.log('Sorted Value Test', sortedValue)
    return ( 
        <Dialog 
            fullWidth
            open={status} 
            onClose={handler}
            TransitionComponent={BRFTransition}>

            <AppBar sx={{ position: 'relative'}}>
                <Toolbar>

                    <IconButton 
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close">
                            <CloseIcon/>
                    </IconButton>

                </Toolbar>
            </AppBar>

            <DialogContent
                sx={{ backgroundColor: '#0f111f',  maxWidth: '100%'}}>
                
                <Stack
                    direction="column"
                    spacing={2} 
                    justifyContent="center"
                    alignItems="center" 
                    display="flex">

                    <ThemeProvider theme={theme}>

                    <FormControl fullWidth>

                        <InputLabel id="sort_label" sx={{ color: '#66fcf1'}}>Sort By</InputLabel>

                        <Select 
                            sx={{ 
                                borderColor: '#66fcf1', backgroundColor:'#2b2d55', color: '#66fcf1'
                            }}
                            labelId="sort_label"
                            id="sort_select"
                            value={sortedValue}
                            label="Sort By"
                            onChange={handleSortedValue}>

                                {sortInfo.map( (sort_data, key) => (
                                    <MenuItem 
                                        sx={{ backgroundColor: '#0f111f', color: '#66fcf1', ":hover": { backgroundColor: '#2b2d55'}}}
                                        key={key} 
                                        value={sort_data}>
                                            {translateSortInfoArr(sort_data)}
                                    </MenuItem>
                                ))}

                        </Select>

                    </FormControl>
                    </ThemeProvider>


                    <RegularButton 
                            text="Sort" 
                            icon={<SearchIcon/>}
                            onClick={() => {
                                sort(sortedValue)
                                handler() 
                            }}/>

                </Stack>

            </DialogContent>

        </Dialog>
    )
}

export default SortedByPanel