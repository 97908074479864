
import React, { useEffect, useState } from 'react'

import { Dialog, DialogContent, Toolbar, Tooltip, Chip, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { AppBar, Stack } from '@mui/material'

import { IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import ControlPointIcon from '@mui/icons-material/ControlPoint'


import { BRFTransition } from '../../utils/BRFTransition'
import BRFTypography from '../BRFTypography'
import { LicenseContainer } from './LeasingDatePicker' 

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { SubmitButton } from '../BRFButtons'
import { requestLeasing } from '../../session/backend_session/Backend'
import { getConnectedWallet } from '../../session/WalletSession'




const defaultIncrementPrice = parseFloat(0.01)

const SEASON_END = { 
    month: 11,
    day: 1,
    year: 2022
}

const LeasingDurationOptions = 
            ['A Week',
            '1 Month',
            '2 Months',
            '3 Months',
            '4 Months', 
            '5 Months', 
            '6 Months'] 



const LeasingPanel = ({ status, handler, token }) => {

    const [requestStatus, setRequestStatus] = useState(false)
    const [leasingData, setLeasingData] = useState() 
    const [ leaseDuration, setLeaseDuration ] = useState("") 

    const [ manualEdit, setManualEdit ] = useState(false) 

    const [ price, setPrice ] = useState(defaultIncrementPrice)

    const [ message, setMessage ] = useState("")
    const [ messageStatus, setMessageStatus ] = useState(false)
    const [ messageIndicator, setMessageIndicator ]  = useState("primary")
    
    const handleManualEdit = () => { 
        setPrice(defaultIncrementPrice)
        setManualEdit(!manualEdit)
    }

    const handlePriceChange = ( event ) => { 

        if(event.target.value == 0 || event.target.value == NaN){
            setPrice(defaultIncrementPrice)
        }

        if(event.type == 'blur' || event.key == 'Enter'){ 
            const rawPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : 0
            const tempPrice = rawPrice === 0 ? 1 : 0 
            setPrice(tempPrice)
        } else if (event.type == 'change'){ 
            const newPrice = event.target.value != '' ? parseFloat(event.target.value).toFixed(2) : null 
            setPrice(newPrice)
        }

    }

    const handleLeaseDuration = (event) => { 
        setLeaseDuration(event.target.value)
    }


    const increasePrice = () => { 
        setPrice( (x) => x += defaultIncrementPrice)
    }

    const increasePriceFaster = () => { 
        setPrice( (x) => x += parseFloat(1))
    }

    

    const decreasePrice = () => { 
        setPrice( (x) => x -= defaultIncrementPrice)
    }

    const decreasePriceFaster = () => { 
        setPrice( (x) => x -= parseFloat(1))
    }

    const validateNegatives = () => { 
        if(price <= 0){
            return true 
        }
        return false 
    }

    const convertToMaticFigures = () => { 
        return parseFloat(price).toFixed(2)
    }

    
    const configureLeasingData = () => {

        const data = {

            card_id: token.tokenId,
            requested: true,
            offerer: getConnectedWallet(),
            lease_duration: leaseDuration,
            offer_price: convertToMaticFigures()
            
        }

        setLeasingData(data) 
    }


    const handleLeasingSubmit = async (event) => {

            event.preventDefault() 
            console.log('Leasing Data: ', leasingData)
            await requestLeasing(leasingData)
                .then(() => {
                    setRequestStatus(true)
                })
                .catch((error) => {

                    if(error.response.data){ 
                        setMessage(error.response.data.message)
                        setMessageIndicator("error")
                        setMessageStatus(true) 
                    }
                })


    }



    const renderRequestSubmitted = () => {
        return (
            <Stack 
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                display="flex">

                <Typography 
                    sx={{ color: '#000'}}
                    variant="h6"
                    fontFamily="Zelot-Light"
                    component="div">
                    Leasing Sumitted 
                </Typography>

            </Stack>
        )
    }

    useEffect(() => { 

    
    }, [])

    console.log('Token ID', token.tokenId)
    console.log(leaseDuration)
    console.log('Current Price: ', convertToMaticFigures())
    
    return (
        <Dialog 
            fullScreen
            open={status}
            onClose={handler}
            TransitionComponent={BRFTransition}>
                
                <AppBar
                    sx={{ position: 'relative'}}>

                        <Toolbar>

                            <IconButton 
                                edge="start"
                                color="inherit"
                                onClick={handler}
                                aria-label="close">
                                    <CloseIcon/>
                            </IconButton>

                            <BRFTypography text={`Leasing ${token.name}`}/>


                        </Toolbar>
                        
                            
                </AppBar>

                <DialogContent
                    sx={{ backgroundColor: '#111322',   justifyContent: 'center', alignItems: 'center', display: 'flex'}}>

                        <LicenseContainer shadow={3}>
                            <Stack 
                                mt={{xs: 5, md: 10}}
                                direction="column"
                                spacing={5}
                                justifyContent="center"
                                alignItems="center"
                                display="flex">

                                <Typography 
                                    sx={{ color: '#000'}}
                                    variant="h6"
                                    fontFamily="Zelot-Light"
                                    component="div">
                                        Leasing Appplication 
                                </Typography>

                                {!requestStatus ? (
                                 
                                    
                                        <form onSubmit={handleLeasingSubmit}>

                                            <Stack 
                                                direction="column"
                                                spacing={3}
                                                justifyContent="center"
                                                alignItems="center"
                                                display="flex">

                                                    <FormControl fullWidth>
                                                        <InputLabel id="license-duration-id">License Duration</InputLabel>
                                                        <Select
                                                            labelId='license-duration-id'
                                                            id="license-duration-id"
                                                            label="License Duration"
                                                            value={leaseDuration}
                                                            onChange={handleLeaseDuration}>
                                                            
                                                                {LeasingDurationOptions.map((duration, key) => ( 
                                                                    <MenuItem 
                                                                        key={key}
                                                                        value={duration}>
                                                                            {duration}
                                                                    </MenuItem>
                                                                ))}

                                                        </Select>
                                                    </FormControl>


                                                {! manualEdit ? (
                                                    <Stack
                                                        direction="column"
                                                        spacing={2}
                                                        justifyContent="center"
                                                        alignItems="center" 
                                                        display="flex">

                                                        <Chip
                                                            sx={{ 
                                                                color: '#000'
                                                            }}
                                                            variant="contained"
                                                            size="sm"
                                                            label="( hint: double click to increase faster )"/>

                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            justifyContent="center"
                                                            alignItems="center" 
                                                            display="flex">


                                                            {/** If price value is negative, show the disable icon  */}
                                                            {!validateNegatives() ? (
                                                                <Tooltip
                                                                    placement="top-start"
                                                                    title="Decrease Price">
                                                                    <IconButton
                                                                        sx={{ 
                                                                            backgroundColor: '#DE3163',
                                                                                ":hover": {
                                                                                backgroundColor: '#DE3163'
                                                                            }
                                                                            }} 
                                                                        size="large"
                                                                        aria-label="edit listed card"
                                                                        color="inherit"
                                                                        onClick={decreasePrice}
                                                                        onDoubleClick={decreasePriceFaster}
                                                                        >
                                                                        <RemoveCircleOutlineIcon />
                                                                    </IconButton>
                                                                </Tooltip>  
                                                            ): (
                                                                <Tooltip
                                                                    placement="top-start"
                                                                    title="Decrease Price">
                                                                    <IconButton
                                                                        sx={{ 
                                                                            backgroundColor: '#DE3163',
                                                                                ":hover": {
                                                                                backgroundColor: '#DE3163'
                                                                            }
                                                                            }} 
                                                                        size="large"
                                                                        aria-label="edit listed card"
                                                                        color="inherit"
                                                                        disabled 
                                                                        >
                                                                        <RemoveCircleOutlineIcon />
                                                                    </IconButton>
                                                                </Tooltip>  
                                                            )}  



                                                            <Typography
                                                                variant="h6"
                                                                fontFamily="AZN"
                                                                sx={{
                                                                    color: '#52cac1'
                                                                }}>

                                                                    {convertToMaticFigures()}
                                                            </Typography>    


                                                            <Tooltip
                                                                placement="top-start"
                                                                title="Increase Price">
                                                                <IconButton
                                                                    sx={{ 
                                                                        backgroundColor: '#52cac1',
                                                                            ":hover": {
                                                                            backgroundColor: '#52cac1'
                                                                        }
                                                                        }} 
                                                                    size="large"
                                                                    aria-label="edit listed card"
                                                                    color="inherit"
                                                                    onClick={increasePrice}
                                                                    onDoubleClick={increasePriceFaster}
                                                                    >
                                                                    <ControlPointIcon />
                                                                </IconButton>
                                                            </Tooltip>                     

                                                        </Stack>

                                                    </Stack> 
                                                ): (
                                                    <TextField
                                                        sx={{
                                                            background: '#fff',
                                                            color: '#000', 
                                                            
                                                        }}
                                                        variant="outlined"
                                                        type="number"
                                                        value={parseFloat(price)}
                                                        onChange={handlePriceChange}
                                                    />
                                                )}

                                                <Chip
                                                    sx={{
                                                        color: '#000'
                                                    }}
                                                    variant="outlined"
                                                    size="sm"
                                                    label={'edit manually'}
                                                    onClick={handleManualEdit}
                                                />

                                                <SubmitButton text="Request" onClick={configureLeasingData}/>
                                                
                                                {messageStatus && 
                                                    <Chip
                                                        variant="outlined"
                                                        color={messageIndicator}
                                                        size="sm"
                                                        label={message}
                                                    />
                                                }


                                            </Stack>
                                        </form>
                                                        
                                ): (
                                    renderRequestSubmitted()
                                )}                              

                            </Stack>
                        </LicenseContainer>

                </DialogContent>

        </Dialog>
    )
}

export default LeasingPanel 