import CardMedia from '@mui/material/CardMedia'


const WalletIcon = ({icon}) => {

    return (

        <CardMedia
            sx={{height: '150px', width: {xs: '100px', sm: '100px', md: '100px'}, objectFit: 'contain'}}
            component="img"
            src={icon}
            alt='WalletIcon'>

        </CardMedia>
                            
    )
}

export default WalletIcon 