import React, { useState, useEffect } from 'react' 


import {Box, Stack, Grow} from '@mui/material'
import {Card, CardContent} from '@mui/material'
import { Typography } from '@mui/material'


import BRFPanel from '../../components/BRFPanel'
import { RegularButton } from '../../components/BRFButtons'
import ViewTransactions from '../../components/ViewTransactions'


import { getUserTransactions } from '../../session/backend_session/Backend'
import BRFTypography from '../../components/BRFTypography'



const Transactions  = () => {

    const [ transactionsContainer, setTransactionsContainer ] = useState([])
    const [ currentTransaction, setCurrentTransaction ] = useState({})
    const [transactionStatus, setTransactionStatus] = useState(false)
    
    const handleViewTransaction = () => {
        setTransactionStatus(!transactionStatus)
    }

    const mountUserTransactions = async () => { 

        const transactions = [] 
        const transactions_data = await getUserTransactions()
        if(transactions_data.data){

            const sender = transactions_data.data.sender
            const receiver = transactions_data.data.receiver 

            sender.map((data) => { 
                transactions.push(data)
            })

            receiver.map((data) => { 
                transactions.push(data)
            })
            
        }

        setTransactionsContainer(transactions)



    }

    console.log(transactionsContainer)

    useEffect(() => { 

        mountUserTransactions() 

    }, [])

 
    return (
        <Box             
            mt={{xs: -10, md: -8}}>
            
            <Stack 
                direction="column"
                spacing={{xs: 2, md: 4}}
                justifyContent="center"
                alignItems="center"
                width="100%"
                maxWidth="100%">


                    <BRFTypography
                        text="Transactions"/>
                    <BRFPanel shadow={0}>


                      
                            {!transactionsContainer.length == 0 ? (

                            <Stack 
                                mt={5}
                                direction="column"
                                spacing={3}
                                justifyContent="center"
                                alignItems="center"
                                
                            
                                >


                                {/* Change later */}
                                {transactionsContainer.map( (transaction, key) => (

                                <Grow 
                                    key={key}
                                    in={true}
                                    style={{ transformOrigin: '0 0 0'}}
                                    {...(true ? { timeout: 3000} : {} )}>
                                    <Card
                                        key={key}
                                        sx={{ 

                                            backgroundColor: '#292b38',
                                            width: { xs: '100%', sm: '100%', md: '90%', lg: "80%"}, height: '100px',
                                            
                                            }}>
                                        <CardContent>

                                            <Stack 
                                                direction="row"
                                                spacing={5}
                                                justifyContent="center"
                                                alignItems="center"
                                                display="flex"
                                                width="100%">

                                                    <Stack 
                                                        direction="column"
                                                        justifyContent="start" 
                                                        alignItems="start" 
                                                        spacing={2}>

                                                    

                                                        <Typography 
                                                            
                                                            sx={{ color: '#ffffff', fontSize: {xs: '.6rem', md: '1rem'}}}
                                                            fontFamily='AZN'
                                                            component="div">
                                                            {`#${transaction.id}`}
                                                        </Typography>

                                                        

                                                    </Stack>


                                                    <Stack 
                                        
                                                        direction="column"
                                                        justifyContent="end" 
                                                        alignItems="end" 
                                                        spacing={2}>

                                                        <RegularButton 
                                                            text="view"
                                                            onClick={() => { 

                                                                setCurrentTransaction(transaction)
                                                                handleViewTransaction() 

                                                            }}/>

                                                    </Stack>



                                                    

                                            </Stack>

                                        </CardContent>
                                    </Card>
                                </Grow> 

                                ))}

                            </Stack>
                            ): (
                                <Stack 
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex">

                                        <BRFTypography
                                            text={"No Transactions yet!"}/>

                                </Stack>
                            )}

                    </BRFPanel>

            </Stack>

            <ViewTransactions 
                status={transactionStatus} 
                handler={handleViewTransaction} 
                transaction={currentTransaction}/>

        </Box>
    )
}

export default Transactions  